import {
  IDataLayerMain,
  IDataProductMeta,
  OdpDataAccessSolutions,
} from "odp-sdk/dist/types"
import React from "react"
import { useDispatch } from "react-redux"
import { setExplorerLayersToSelectAC } from "../../../../../utils/redux/actions/catalogActions"
import { S } from "./Tabs.shared.style"

type Props = {
  dataProduct: IDataProductMeta
  explorerLayers?: IDataLayerMain[]
}

const DataAccessTab = ({ dataProduct, explorerLayers }: Props) => {
  const dispatch = useDispatch()
  const clickAddToExplorer = () => {
    if (explorerLayers)
      dispatch(setExplorerLayersToSelectAC({ layers: explorerLayers }))
  }

  const availableInExplorer = !!explorerLayers?.length
  const availableInConnector = !!dataProduct.availableIn.includes(
    OdpDataAccessSolutions.ODC
  )
  const showSeparator = !!availableInExplorer && availableInConnector

  return (
    <S.Tab>
      <h4>Data Access</h4>
      <div>
        <strong>Access Type: </strong>
        {dataProduct.accessType}
      </div>
      <div>
        <strong>Homepage: </strong>
        {dataProduct.homepage}
      </div>
      <div>
        <strong>License: </strong>
        {dataProduct.license}
      </div>
      <div>
        <strong>Contact: </strong>
        {dataProduct.contact}
      </div>
      {dataProduct.availableIn.length > 0 && (
        <div>
          <strong>Platform Accessibility: </strong>
          {availableInExplorer && (
            <S.ClickableSpan onClick={clickAddToExplorer}>
              Explorer
            </S.ClickableSpan>
          )}
          {showSeparator && <S.SeparatorSpan>,</S.SeparatorSpan>}
          {availableInConnector && (
            dataProduct.odcNotebookLink
              ? <a href={dataProduct.odcNotebookLink} target={"_blank"} rel="noreferrer">Connector</a>
              : <span>Connector</span>
            )
          }
        </div>
      )}
    </S.Tab>
  )
}

export default DataAccessTab
