import styled from "styled-components"
import "@fontsource/roboto/300.css"
import { colors } from "../../../styles/colors.style"
import bgConnectorOdc from "../../../assets/img/connector/connector-odc-background.svg"
import { token } from "../../../components/OdpGlobalComponents/tokens"

const ConnectorOdcContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 80vh;
  padding: 2rem;

  margin: 30px;
  border-radius: 10px;
  max-width: 1000px;

  text-align: center;
  color: white;
  font-size: 14px;
  letter-spacing: 0.03em;

  background: url("${bgConnectorOdc}"), ${colors.mainViolet};
  background-position: bottom;
  background-repeat: no-repeat;
`
const ConnectorOdcHeadline = styled.div`
  font-family: "Helvetica Neue", "Arial", sans-serif;
  font-weight: 300;
  font-size: 24px;
  padding: 1rem;
`
const ConnectorOdcDescription = styled.div`
  font-family: ${token.typography.family.FAMILY_1};
  font-weight: 300;
  line-height: 26px;
  padding: 0 3rem 1rem 3rem;
`

const ConnectorOdcExamplesWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
`
const ConnectorOdcExampleImgs = styled.img`
  padding: 3rem 1rem;
  &:first-of-type {
    padding-left: 3rem;
  }
  &:last-of-type {
    padding-right: 3rem;
  }
  width: 45%;
`

export const S = {
  ConnectorOdcContentWrapper,
  ConnectorOdcHeadline,
  ConnectorOdcDescription,
  ConnectorOdcExamplesWrapper,
  ConnectorOdcExampleImgs,
}
