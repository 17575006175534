import React from "react"
import storiesPreviewSource from "../../assets/img/stories-preview.png"
import ComingSoon from "../../components/ComingSoonPage/ComingSoon"

const ProjectsComingSoon = () => {
  return (
    <ComingSoon 
      title="Stories"
      paragraphs={[
        "Stories are graphic interactive reports where data is brought to life."
      ]}
      imageSource={storiesPreviewSource}
      imageDescription={"Explore the stories behind the data"}
    />
  )
}

export default ProjectsComingSoon
