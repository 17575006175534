import { FormGroup} from '@mui/material'
import React from 'react'
import { useDispatch } from 'react-redux'
import { useODPSelector } from '../../../../../../utils/helpers/useAppStateSelector'
import { setExplorerLayersToSelectAC } from '../../../../../../utils/redux/actions/catalogActions'
import AddLayer from './AddLayer'
import { S } from './AddLayersToExplorerModal.sytle'

const AddLayersToExplorerModal = () => {
  const displayableLayers = useODPSelector(state => state.catalog.explorerLayersToSelect)
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(setExplorerLayersToSelectAC({ layers: []}))
  }

  return (
    <S.Modal
      open={!!displayableLayers.length}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <S.Container>
        <FormGroup>
          {displayableLayers.map(datasetLayer => (
            <AddLayer key={'addbox' + datasetLayer.id } dataLayer={datasetLayer} />
          ))}
          </FormGroup>
      </S.Container>
    </S.Modal>
  )
}

export default AddLayersToExplorerModal