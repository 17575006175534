import styled from "styled-components"
import { StyledPage } from "../../styles/shared.style"

const ConnectorPage = styled(StyledPage)`
  display: flex;
  height: 100vh;
`

const ConnectorMenu = styled.div`
  flex-grow: 1;
  flex-basis: 0;
  min-width: 350px;
`
const ConnectorContent = styled.div`
  flex-grow: 2;
  padding: 2rem;
`

const ConnectorHeader = styled.div`
  padding: 2rem;
`

export const S = {
  ConnectorPage,
  ConnectorMenu,
  ConnectorContent,
  ConnectorHeader,
}
