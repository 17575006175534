import React, { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/pro-light-svg-icons"
import SearchIcon from "../icons/SearchIcon"
import styled, { css } from "styled-components"
import { token } from "../OdpGlobalComponents/tokens"
import { colors } from "../../styles/colors.style"

interface ContainerProps {
  fullWidth: boolean
}

interface FieldProps {
  filled: boolean
}

const SearchFieldContainer = styled.div<ContainerProps>`
  width: 100%;
  max-width: ${props => (props.fullWidth ? "100%" : "280px")};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const ClearButton = styled.button`
  height: 30px;
  width: 30px;
  padding: 0;
  background-color: transparent;
  border: none;
  position: absolute !important;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  z-index: 3 !important;
  cursor: pointer;
  transition: 0.3s ease;

  &:hover,
  &:focus {
    transform: translateY(-50%) scale(1.2);
  }
`

const SearchInput = styled.input<FieldProps>`
  padding: 6px 10px 6px 10px;
  background-color: transparent;
  border: 1px solid ${colors.mainVioletO30};
  border-radius: ${token.border.radius.RADIUS_2};
  width: 100%;
  max-width: 240px;
  transition: 0.3s ease;
  position: relative;

  ${props =>
    props.filled &&
    css`
      border-color: ${colors.mainViolet};
      padding-right: 22px;
    `}
  &::placeholder {
    color: ${colors.mainVioletO30};
  }
  &:focus {
    box-shadow: 0 0 0 3px ${colors.mainVioletO30};
    border-color: ${colors.mainViolet};
  }
`

interface Props {
  onChange: (value: string) => void
  placeholder: string
  value: string
  handleClear?: () => void
  fullWidth?: boolean
  type?: string
}

const SearchField = ({
  onChange,
  placeholder,
  value,
  handleClear,
  fullWidth,
}: Props) => {
  const [filled, setFilled] = useState(false)

  const handleChange = (searchPhrase: string) => {
    searchPhrase !== "" ? setFilled(true) : setFilled(false)
    onChange(searchPhrase)
  }

  const eraseInput = () => {
    if (handleClear) handleClear()
    setFilled(false)
  }

  return (
    <SearchFieldContainer fullWidth={!!fullWidth}>
      <SearchIcon />
      <SearchInput
        value={value}
        placeholder={placeholder}
        filled={filled}
        onChange={e => handleChange(e.target.value)}
      />
      {value !== "" && (
        <ClearButton onClick={eraseInput}>
          <FontAwesomeIcon icon={faTimes} size={"1x"} color={colors.mainViolet} />
        </ClearButton>
      )}
    </SearchFieldContainer>
  )
}

export default SearchField
