import { ReactPlugin } from "@microsoft/applicationinsights-react-js"
import { ApplicationInsights } from "@microsoft/applicationinsights-web"
import { createBrowserHistory } from "history"

/**
 * DOCS: https://docs.microsoft.com/en-us/azure/azure-monitor/app/javascript-react-plugin
 */

// Set instrumentation key conditionally
// process.env.NODE_ENV !== "production"
const INSTRUMENTATION_KEY = "effdda3c-7fa3-421a-a634-62413c234023"

const browserHistory = createBrowserHistory({ basename: "" })
const reactPlugin = new ReactPlugin()
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: INSTRUMENTATION_KEY,
    extensions: [reactPlugin],
    maxAjaxCallsPerView: 2000,
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
    },
  },
})
appInsights.loadAppInsights()
appInsights.trackPageView()

export { reactPlugin, appInsights }
