import { createAction } from "@reduxjs/toolkit"
import { AuthorizedGroups } from "../../useAuth"
import { IUserInfo } from "../reducers/user"

export enum UserActionNames {
  RESET_USER_STATE = "RESET_USER_STATE",
  SET_USER_INFO = "SET_USER_INFO",
  SET_AUTHORIZED_GROUPS = "SET_AUTHORIZED_GROUPS",
}

export const resetUserStateAC = createAction(UserActionNames.RESET_USER_STATE)
export const setUserInfoAC = createAction<{
  userInfo: IUserInfo
  authorizedGroups: Array<AuthorizedGroups>
}>(UserActionNames.SET_USER_INFO)
export const setAuthorizedGroupsAC = createAction<{
  authorizedGroups: Array<AuthorizedGroups>
}>(UserActionNames.SET_AUTHORIZED_GROUPS)
