import { css } from "styled-components"
import { typeScale } from "../../confTypeScale"
import { mediaMin } from "../../mixMediaQuery"
import { token } from "../../tokens"

export const GlobalHeading = css`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 1.5rem;
    margin-block-start: 0;
  }

  h1 {
    /* Regular */
    font-weight: ${token.typography.weight.WEIGHT_3};
  }

  h4 {
    /* Semi-bold */
    font-weight: ${token.typography.weight.WEIGHT_4};
  }

  h2,
  h3,
  h5,
  h6 {
    /* Bold */
    font-weight: ${token.typography.weight.WEIGHT_5};
  }

  h1 {
    margin-top: 0;

    ${typeScale.PRESET_8}

    ${mediaMin.WIDTH_2} {
      ${typeScale.PRESET_9}
    }

    ${mediaMin.WIDTH_3} {
      ${typeScale.PRESET_10}
    }
  }

  h2 {
    ${typeScale.PRESET_7};

    ${mediaMin.WIDTH_2} {
      ${typeScale.PRESET_8}
    }

    ${mediaMin.WIDTH_3} {
      ${typeScale.PRESET_9}
    }
  }

  h3 {
    ${typeScale.PRESET_6};

    ${mediaMin.WIDTH_2} {
      ${typeScale.PRESET_7}
    }

    ${mediaMin.WIDTH_3} {
      ${typeScale.PRESET_8}
    }
  }

  h4 {
    ${typeScale.PRESET_4};

    ${mediaMin.WIDTH_2} {
      ${typeScale.PRESET_5}
    }

    ${mediaMin.WIDTH_3} {
      ${typeScale.PRESET_6}
    }
  }

  h5 {
    ${typeScale.PRESET_3}

    ${mediaMin.WIDTH_2} {
      ${typeScale.PRESET_4}
    }
  }

  h6 {
    text-transform: uppercase;
    letter-spacing: 0.03em;

    ${typeScale.PRESET_2};
  }
`
