import * as React from "react"

import { S } from "./Loader.style"

type Props = {
  color?: string
}

export const Loader = (props: Props) => {
  return (
    <S.Loader >
      {Array.from({length: 5}, (_, i) => <S.LoaderCircle key={'loader_circle' + i} {...props}/>)}
    </S.Loader>
  )
}
