import styled from "styled-components"
import { mediaMax } from "../../components/OdpGlobalComponents/mixMediaQuery"

const NotFoundPage = styled.div`
  display: flex;
  flex-direction: row;

  ${mediaMax.WIDTH_2} {
    height: 100vh;
  }
`

const Logo = styled.img`
  max-height: 100vh;
  max-width: 50vw;

  ${mediaMax.WIDTH_2} {
    position: absolute;
    visibility: hidden;
  }
`

const Text = styled.div`
  margin: auto;
  padding: 1rem;
`

export const Styled = {
  NotFoundPage,
  Text,
  Logo
}
