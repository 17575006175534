import React, { useEffect, useState } from "react"
import { S } from "./FilterPanel.style"
import { FormGroup, Checkbox } from "@mui/material"
import ChevronUpIcon from "../../../../../components/icons/ChevronUpIcon"
import { useODPSelector } from "../../../../../utils/helpers/useAppStateSelector"
import { useDispatch } from "react-redux"
import { setExplorerFilterAC } from "../../../../../utils/redux/actions/explorerActions"
import {
  clearFiltersAC,
  setFilterEndYearAC,
  setFilterStartYearAC,
  toggleFilterConnectorAvailableDatasetsAC,
  toggleFilterExplorerAvailableDatasetsAC,
  toggleFilterGriddedAC,
  toggleFilterPrivateDatasetsAC,
  toggleFilterPublicDatasetsAC,
} from "../../../../../utils/redux/actions/catalogActions"

const DCRefinePanel = () => {
  const [expanded, setExpanded] = useState(true)
  const dispatch = useDispatch()
  const filters = useODPSelector(state => state.catalog.filters)
  const [startYear, setStartYear] = useState<number | undefined>(undefined);
  const [endYear, setEndYear] = useState<number | undefined>(undefined);
  const [startYearError, setStartYearError] = useState("");
  const [endYearError, setEndYearError] = useState("");

  const restrictedValue = (value?: number) => {
    if (!value) return 0;
    const max = new Date().getFullYear()
    if (value && value < 0) return 0
    if (value && value > max) return max
    return value
  }

  const startYearChange = (value?: number) => {
    const valueToSet = restrictedValue(value)
    setStartYear(valueToSet)
  }
  
  const endYearChange = (value?: number) => {
    const valueToSet = restrictedValue(value)
    setEndYear(valueToSet)
  }

  useEffect(() => {
    let startYearErrorToSet = ""
    let endYearErrorToSet = ""

    if (startYear && startYear < 1000) startYearErrorToSet = 'Value should have 4 digits'
    if (endYear && endYear < 1000) endYearErrorToSet = 'Value should have 4 digits'

    if (startYear && endYear && startYear > endYear) {
      if (!startYearErrorToSet) startYearErrorToSet = 'Date must be chronological'
      if (!endYearErrorToSet) endYearErrorToSet = 'Date must be chronological'
    }
    setStartYearError(startYearErrorToSet)
    setEndYearError(endYearErrorToSet)
  }, [filters.startYear, filters.endYear, startYear, endYear])

  
  useEffect(() => {
    if (startYearError || endYearError) return;

    dispatch(setFilterStartYearAC({ startYear }))
    if (startYear) {
      dispatch(
        setExplorerFilterAC({
          key: "startDate",
          value: new Date(startYear, 0, 1),
        })
      )
    }
    dispatch(setFilterEndYearAC({ endYear: endYear }))
    if (endYear) {
      dispatch(
        setExplorerFilterAC({
          key: "endDate",
          value: new Date(endYear, 0, 1),
        })
      )
    }
  }, [dispatch, endYear, endYearError, startYear, startYearError])


  useEffect(() => {
    return () => {
      dispatch(clearFiltersAC())
    }
  }, [dispatch])

  return (
    <S.FilterPanel>
      <S.FilterPanelSegment style={!expanded ? { borderRight: "none" } : {}}>
        <span>refine</span>
        {expanded && (
          <S.FilterPanelSegmentContent>
            <S.FilterDate>
              <span>Begin Date</span>
              <S.MaterialYearInput
                size="small"
                placeholder="YYYY"
                type="number"
                error={!!startYearError}
                helperText={startYearError}
                onChange={e =>
                  startYearChange(parseInt(e.target.value))
                }
                value={startYear || ""}
              />
            </S.FilterDate>
            <S.FilterDate>
              <span>End Date</span>
              <S.MaterialYearInput
                size="small"
                placeholder="YYYY"
                type="number"
                value={endYear || ""}
                error={!!endYearError}
                helperText={endYearError}
                onChange={e =>
                  endYearChange(parseInt(e.target.value))
                }
              />
            </S.FilterDate>
          </S.FilterPanelSegmentContent>
        )}
      </S.FilterPanelSegment>
      <S.FilterPanelSegment>
        <span>filter results</span>
        {expanded && (
          <S.FilterPanelSegmentContent>
            <FormGroup>
              <S.FormControlLabel
                control={
                  <Checkbox
                    checked={filters.isPublic}
                    onClick={() => dispatch(toggleFilterPublicDatasetsAC())}
                  />
                }
                label={"Public"}
                key={"chckbx" + "Public"}
              />
              <S.FormControlLabel
                control={
                  <Checkbox
                    checked={filters.isPrivate}
                    onClick={() => dispatch(toggleFilterPrivateDatasetsAC())}
                  />
                }
                label={"Private"}
                key={"chckbx" + "Private"}
              />
              <S.FormControlLabel
                control={
                  <Checkbox
                    checked={filters.availableInConnector}
                    onClick={() =>
                      dispatch(toggleFilterConnectorAvailableDatasetsAC())
                    }
                  />
                }
                label={"Available in the Ocean Data Connector"}
                key={"chckbx" + "Available in the Ocean Data Connector"}
              />
              <S.FormControlLabel
                control={
                  <Checkbox
                    checked={filters.availableInExplorer}
                    onClick={() =>
                      dispatch(toggleFilterExplorerAvailableDatasetsAC())
                    }
                  />
                }
                label={"Available in the Ocean Data Explorer"}
                key={"chckbx" + "Available in the Ocean Data Explorer"}
              />
              <S.FormControlLabel
                control={
                  <Checkbox
                    checked={filters.gridded}
                    onClick={() =>
                      dispatch(toggleFilterGriddedAC())
                    }
                  />
                }
                label={"Gridded"}
                key={"chckbx" + "Gridded"}
              />
            </FormGroup>
          </S.FilterPanelSegmentContent>
        )}
      </S.FilterPanelSegment>
      <S.PanelExpandBtn onClick={() => setExpanded(!expanded)}>
        <ChevronUpIcon isOpen={expanded} />
      </S.PanelExpandBtn>
    </S.FilterPanel>
  )
}

export default DCRefinePanel
