import { css } from "styled-components";
import { typeScale } from "../../confTypeScale";
import { mediaMin } from "../../mixMediaQuery";
import { token } from "../../tokens";

export const GlobalBlockquote = css`
  blockquote {
    padding-top: ${token.space.SPACING_6};
    margin-top: ${token.space.SPACING_10};
    margin-bottom: ${token.space.SPACING_8};
    position: relative;

    &::before {
      content: "“";
      font-size: 5rem;
      font-family: ${token.typography.family.FAMILY_2};
      position: absolute;
      top: 24px;
      left: -12px;

      ${mediaMin.WIDTH_2} {
        font-size: 6.125rem;
        left: -14px;
      }

      ${mediaMin.WIDTH_3} {
        font-size: 7.5rem;
        left: -16px;
      }
    }

    p {
      margin-bottom: ${token.space.SPACING_8};
      font-weight: ${token.typography.weight.WEIGHT_3};

      ${typeScale.PRESET_3}

      ${mediaMin.WIDTH_2} {
        ${typeScale.PRESET_7}
      }

      ${mediaMin.WIDTH_3} {
        ${typeScale.PRESET_8}
      }
    }

    footer {
      display: block;

      ${typeScale.PRESET_2}

      cite {
        font-weight: ${token.typography.weight.WEIGHT_5};
        display: block;
      }
    }
  }
`;
