import { Modal as MUIModal } from "@mui/material"
import styled from "styled-components"

const Modal = styled(MUIModal)`
  display: flex;
  align-items: center;
`

const Container = styled.div`
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  max-width: 40%;
  margin: auto;
`

export const S = {
  Container,
  Modal
}
