import { ICastRow } from "odp-sdk"

import {
  IInfoPanelData,
  SET_INFO_PANEL_DATA,
  SET_INFO_PANEL_DATA_UNCLUSTERED,
} from "../actions/infoPanelActions"

const initialState: IInfopanelReduxState = {
  infoPanelData: undefined,
}

export interface IInfopanelReduxState {
  infoPanelData?: IInfoPanelData
}

const infoPanel = (state: IInfopanelReduxState = initialState, action) => {
  switch (action.type) {
    case SET_INFO_PANEL_DATA:
      return Object.assign({}, state, { infoPanelData: action.parsedData })
    case SET_INFO_PANEL_DATA_UNCLUSTERED:
      return Object.assign({}, state, { infoPanelData: action.data })
    default:
      return state
  }
}

export default infoPanel
