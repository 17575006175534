import * as React from "react"
import { Styled } from "./NotFoundPage.style"
import errorPageLogo from "../../assets/img/errorPagesLogo.png"

const NotFoundPage = () => {
  return (
    <Styled.NotFoundPage>
      <Styled.Logo src={errorPageLogo} />
      <Styled.Text>
        <h3>We are sorry but this page does not exist...</h3>
        <p>Sorry, the page you were looking for could not be found.</p>
        <p>
          {"You can return to the "}
          <a href="/">main page</a>
          {" or "}
          <a href="mailto:info@oceandata.earth">contact us</a>
          {" if you can't find what you are looking for!"}
        </p>
      </Styled.Text>
    </Styled.NotFoundPage>
  )
}

export default NotFoundPage
