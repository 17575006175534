import styled from "styled-components"
import { token } from "../../components/OdpGlobalComponents/tokens"
import { colors } from "../../styles/colors.style"

const WelcomePage = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${colors.mainViolet};

  h2 {
    color: ${colors.white};
    font-family: ${token.typography.family.FAMILY_2};
    margin-bottom: 0;
    font-size: 18px;
  }
`

export const Styled = {
  WelcomePage,
}
