import styled from "styled-components"

const MapWrapper = styled.div`
  width: 380px;
  height: 380px;

  .mapboxgl-ctrl-attrib {
    height: 24px;
  }
`

export const S = {
  MapWrapper,
}
