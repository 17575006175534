import React, { useState } from "react"
import { SharedStyled } from "../../styles/shared.style"
import { S } from "./Connector.style"
import ConnectorOdc from "./components/ConnectorOdc"
import ConnectorPackageList from "./components/ConnectorPackageList"
import ConnectorSdkList from "./components/ConnectorSdkList"
import ConnectorVerticalTabPanel from "./components/ConnectorVerticalTabPanel"

const connectorMenuComponents = [
  {
    id: 0,
    component: <ConnectorOdc key="odc-connector-0" />,
  },
  {
    id: 1,
    component: <ConnectorSdkList key="odc-sdks-1" />,
  },
  {
    id: 2,
    component: <ConnectorPackageList key="odc-packages-2" />,
  },
]

const Connector = () => {
  const [activeConnectorContent, setActiveConnectorContent] =
    useState<JSX.Element>(() => connectorMenuComponents[0].component)
  return (
    <S.ConnectorPage>
      <S.ConnectorMenu>
        <S.ConnectorHeader>
          <SharedStyled.h2_Odp>Connector</SharedStyled.h2_Odp>
          <SharedStyled.p_Odp>
            Connect to the platform directly through the Ocean Data Connector, a
            Dask environment. You can also connect through API and SDK.
          </SharedStyled.p_Odp>
        </S.ConnectorHeader>
        <ConnectorVerticalTabPanel
          handleContentViewChange={(index: number) => {
            const newConnectorViewComp = connectorMenuComponents.find(
              el => el.id === index
            )
            if (newConnectorViewComp) {
              setActiveConnectorContent(newConnectorViewComp.component)
            }
          }}
        />
      </S.ConnectorMenu>
      <S.ConnectorContent>{activeConnectorContent}</S.ConnectorContent>
    </S.ConnectorPage>
  )
}

export default Connector
