import { createReducer } from "@reduxjs/toolkit"
import _ from "lodash"
import { IDataProductResult, OdpDataAccessSolutions } from "odp-sdk/dist/types"
import { ICatalogFilters, initialCatalogState } from "../initialState"
import {
  toggleFilterConnectorAvailableDatasetsAC,
  toggleFilterExplorerAvailableDatasetsAC,
  getCatalogResultsAC,
  toggleFilterPrivateDatasetsAC,
  toggleFilterPublicDatasetsAC,
  setFilterStartYearAC,
  setFilterEndYearAC,
  setExplorerLayersToSelectAC,
  clearFiltersAC,
  toggleFilterGriddedAC,
} from "../actions/catalogActions"

const filteredResults = (
  filters: ICatalogFilters,
  results: IDataProductResult[]
): IDataProductResult[] => {
  let filteredResults = [...results]
  if (filters.isPrivate)
    filteredResults = filteredResults.filter(
      el => el.dataProductResult.accessType === "private"
    )
  if (filters.isPublic)
    filteredResults = filteredResults.filter(
      el => el.dataProductResult.accessType === "public"
    )
  if (filters.availableInConnector)
    filteredResults = filteredResults.filter(el =>
      el.dataProductResult.availableIn.includes(OdpDataAccessSolutions.ODC)
    )
  if (filters.availableInExplorer)
    filteredResults = filteredResults.filter(el => el.dataLayersMain.length > 0)
  if (filters.gridded)
    filteredResults = filteredResults.filter(el =>
      el.dataProductResult.tags.includes("gridded")
    )

  const applyYearFilter =
    filters.startYear !== undefined || filters.endYear !== undefined
  if (applyYearFilter)
    filteredResults = filterYearRange(
      filters.startYear || 0,
      filters.endYear || 99999,
      filteredResults
    )

  return filteredResults
}

export const rangesDoOverlap = (
  rangeA: [number, number],
  rangeB: [number, number]
): boolean => {
  const [rAstart, rAend] = rangeA
  const [rBstart, rBend] = rangeB
  if (_.inRange(rAstart, rBstart, rBend)) return true
  if (_.inRange(rAend, rBstart, rBend + 1)) return true
  if (_.inRange(rBstart, rAstart, rAend)) return true
  if (_.inRange(rBend, rAstart, rAend + 1)) return true

  return false
}

export const filterYearRange = (
  filterStartYear: number,
  filterEndYear: number,
  results: IDataProductResult[]
): IDataProductResult[] => {
  return results.filter(el => {
    const timespan = el.dataProductResult.timespan
    if (!timespan || timespan.length !== 2) return true // returns all datasets with not defined/broken timespan;

    const result = rangesDoOverlap(timespan as [number, number], [
      filterStartYear,
      filterEndYear,
    ])
    return result
  })
}

const catalog = createReducer(
  () => initialCatalogState,
  builder => {
    builder
      .addCase(toggleFilterPublicDatasetsAC, state => {
        if (!state.filters.isPublic) {
          state.filters.isPublic = true
          state.filters.isPrivate = false
        } else {
          state.filters.isPublic = false
        }
        state.filteredResults = filteredResults(state.filters, state.results)
      })
      .addCase(toggleFilterPrivateDatasetsAC, state => {
        if (!state.filters.isPrivate) {
          state.filters.isPrivate = true
          state.filters.isPublic = false
        } else {
          state.filters.isPrivate = false
        }
        state.filteredResults = filteredResults(state.filters, state.results)
      })
      .addCase(toggleFilterExplorerAvailableDatasetsAC, state => {
        state.filters.availableInExplorer = !state.filters.availableInExplorer
        state.filteredResults = filteredResults(state.filters, state.results)
      })
      .addCase(toggleFilterConnectorAvailableDatasetsAC, state => {
        state.filters.availableInConnector = !state.filters.availableInConnector
        state.filteredResults = filteredResults(state.filters, state.results)
      })
      .addCase(toggleFilterGriddedAC, state => {
        state.filters.gridded = !state.filters.gridded
        state.filteredResults = filteredResults(state.filters, state.results)
      })
      .addCase(getCatalogResultsAC, (state, action) => {
        state.results = action.payload.results
        state.filteredResults = filteredResults(
          state.filters,
          action.payload.results
        )
      })
      .addCase(setFilterStartYearAC, (state, action) => {
        state.filters.startYear = action.payload.startYear
        state.filteredResults = filteredResults(state.filters, state.results)
      })
      .addCase(setFilterEndYearAC, (state, action) => {
        state.filters.endYear = action.payload.endYear
        state.filteredResults = filteredResults(state.filters, state.results)
      })
      .addCase(setExplorerLayersToSelectAC, (state, action) => {
        state.explorerLayersToSelect = action.payload.layers
      })
      .addCase(clearFiltersAC, state => {
        state.filteredResults = state.results
        state.filters = initialCatalogState.filters
      })
  }
)

export default catalog
