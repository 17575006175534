export const token = {
  border: {
    radius: {
      RADIUS_2: "4px",
      RADIUS_3: "8px",
      RADIUS_4: "9999px", // Pill
      RADIUS_5: "100%", // Circle
    },
    width: {
      WIDTH_1: "1px",
      WIDTH_4: "8px",
    },
  },
  space: {
    SPACING_1: "0.250rem", // 4px
    SPACING_2: "0.500rem", // 8px
    SPACING_3: "0.750rem", // 12px
    SPACING_4: "1.000rem", // 16px
    SPACING_5: "1.250rem", // 20px
    SPACING_6: "1.500rem", // 24px
    SPACING_7: "1.750rem", // 28px
    SPACING_8: "2.000rem", // 32px
    SPACING_9: "2.250rem", // 36px
    SPACING_10: "2.500rem", // 40px
    SPACING_11: "2.750rem", // 44px
    SPACING_12: "3.000rem", // 48px
    SPACING_13: "3.250rem", // 52px
    SPACING_14: "3.500rem", // 56px
    SPACING_20: "5.000rem", // 80px
  },
  transition: {
    duration: {
      SPEED_3: "300ms", // Default
      SPEED_4: "500ms",
    },
    timing: {
      EFFECT_2: "ease-in-out",
      EFFECT_3: "cubic-bezier(0.7, 0, 0.2, 1)", // Default
    },
  },
  typography: {
    family: {
      FAMILY_1: ["'Roboto', Helvetica, Arial, sans-serif"],
      FAMILY_2: ["'lora', monospace"],
    },
    lineHeight: {
      HEIGHT_00: "1.250rem", // "20px", // custom
      HEIGHT_0: "1.375rem", // "22px", // font-size: 12px
      HEIGHT_1: "1.500rem", // "24px", // font-size: 14px
      HEIGHT_2: "1.750rem", // "28px", // font-size: 16px
      HEIGHT_3: "2.000rem", // "32px", // font-size: 18px
      HEIGHT_4: "2.125rem", // "26px", // font-size: 20px
      HEIGHT_5: "2.125rem", // "34px", // font-size: 22px
      HEIGHT_6: "1.875rem", // "30px", // font-size: 24px
      HEIGHT_7: "2.375rem", // "38px", // font-size: 28px
      HEIGHT_8: "2.875rem", // "46px", // font-size: 34px
      HEIGHT_9: "3.500rem", // "56px", // font-size: 48px
      HEIGHT_10: "4.563rem", // "73px", // font-size: 60px
      HEIGHT_11: "7.250rem", // "116px", // font-size: 96px
    },
    size: {
      SIZE_0: "0.750rem", // 12px / Small
      SIZE_1: "0.875rem", // 14px / Small
      SIZE_2: "1rem", // 16px / Body / H6 / H3 Space Mono / Tertiary Button
      SIZE_3: "1.125rem", // 18px / H2 Space Mono
      SIZE_4: "1.250rem", // 20px / H5 Bold / P Large
      SIZE_5: "1.375rem", // 22px / Body Space Mono
      SIZE_6: "1.500rem", // 24px / H4 Semi-bold
      SIZE_7: "1.750rem", // 28px / H1 Space Mono
      SIZE_8: "2.125rem", // 34px / H3 Bold
      SIZE_9: "3.000rem", // 48px / H2 Bold
      SIZE_10: "3.750rem", // 60px / H1
      SIZE_11: "6.000rem", // 96px / H1 Large
    },
    weight: {
      WEIGHT_2: 400,
      WEIGHT_3: 500,
      WEIGHT_4: 600,
      WEIGHT_5: 700,
    },
  },
};
