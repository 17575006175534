import React, { useEffect, useState } from "react"

import vesselEmissionsTrackerImg from "../../../assets/img/app-view/vessel_emissions_tracker_app.png"
import vesselEmissionsSimulatorImg from "../../../assets/img/app-view/vessel_emissions_simulator.png"
import saferOceanEconomyImg from "../../../assets/img/app-view/safer_ocean_economy.png"
import lusedataImage from "../../../assets/img/app-view/3-Lusedata-img.png"
import { AuthorizedGroups, useAuth } from "../../../utils/useAuth"
import ListCardItem, { ListCardItemProps } from "../../../components/Sidebar/ListCardItem"
import { ListItemGrid } from "../../../components/Sidebar/ListItemGrid"

const appData: ListCardItemProps[] = [
  {
    imgSrc: vesselEmissionsTrackerImg,
    title: "Vessel Emissions Tracker",
    url: "https://vessel-emissions.hubocean.io/",
    description:
      "Track estimated emissions from the global maritime fleet, based on AIS data and vessel particulars following the ICCT methodology.",
    category: "Shipping",
    authorizationGroups: [AuthorizedGroups.APP_VESSEL_EMISSIONS_READER, AuthorizedGroups.ODP_INTERNAL_READ_ALL],
  },
  {
    imgSrc: vesselEmissionsSimulatorImg,
    title: "Vessel Emissions Simulator",
    url: "https://vessel-simulator.hubocean.io/",
    description:
      "Estimate the emissions for an upcoming voyage through route planning and vessel particulars. Compare vessels and estimate the emissions of gases like CO2, Sulfur, Methane and more.",
    category: "Shipping",
    authorizationGroups: [
      AuthorizedGroups.APP_VESSEL_EMISSION_SIMULATOR_READER,
      AuthorizedGroups.ODP_INTERNAL_READ_ALL,
    ],
  },
  {
    imgSrc: lusedataImage,
    title: "Lusedata",
    url: "https://lusedata.hubocean.earth/",
    description:
      "Get an overview of lice in aquaculture facilities around Norway. See how temperature, preventative measures and treatments can help control lice populations.",
    category: "Aquaculture",
    authorizationGroups: [
      AuthorizedGroups.ODP_DEFAULT_ACCESS_GROUP,
      AuthorizedGroups.ODP_GCP_DEFAULT_ACCESS_GROUP,
      AuthorizedGroups.ODP_INTERNAL_READ_ALL,
    ],
  },
  {
    imgSrc: saferOceanEconomyImg,
    title: "Safer Ocean Economy",
    url: "https://safer-ocean.hubocean.io",
    category: "Governance",
    description:
      "Get an overview of the Environment, Safety, Governance, and Economic Conditions at sea. Compare different territories and see the development over time.",
    authorizationGroups: [
      AuthorizedGroups.ODP_DEFAULT_ACCESS_GROUP,
      AuthorizedGroups.ODP_GCP_DEFAULT_ACCESS_GROUP,
      AuthorizedGroups.ODP_INTERNAL_READ_ALL,
    ],
  },
]

interface Props {
  searchQuery: string
  selectedCategory: string
}

const AppList = ({ searchQuery, selectedCategory }: Props) => {
  const [apps, setApps] = useState<ListCardItemProps[]>([])
  const { hasUserAuthorization } = useAuth()

  useEffect(() => {
    setApps(
      appData.sort((a, b) => (a.title > b.title ? 1 : -1)).filter(el => hasUserAuthorization(el.authorizationGroups))
    )
  }, [])

  useEffect(() => {
    const filtered = appData.filter(app => {
      return (
        app.title.toLowerCase().includes(searchQuery.toLowerCase()) &&
        app.category?.toLowerCase().includes(selectedCategory.toLowerCase()) &&
        hasUserAuthorization(app.authorizationGroups)
      )
    })

    setApps([...filtered].sort((a, b) => (a.title > b.title ? 1 : -1)))
  }, [searchQuery, selectedCategory])

  return (
    <ListItemGrid>
      {apps.map((data, index) => {
        return <ListCardItem {...data} key={`appCard${index}`} />
      })}
    </ListItemGrid>
  )
}

export default AppList
