import styled, { css } from "styled-components"
import { mediaMin } from "../components/OdpGlobalComponents/mixMediaQuery"
import { token } from "../components/OdpGlobalComponents/tokens"
import { colors } from "./colors.style"

export const StyledPage = styled.div`
  width: calc(100vw - ${token.space.SPACING_14});
  margin-left: ${token.space.SPACING_14};
  overflow-x: hidden;

  ${mediaMin.WIDTH_2} {
    width: calc(100vw - ${token.space.SPACING_20});
    margin-left: ${token.space.SPACING_20};
  }
  ${mediaMin.WIDTH_3} {
    width: calc(100vw - 5.25rem);
    margin-left: 5.25rem;
  }
`

const h2_Odp = styled.h2`
  font-family: ${token.typography.family.FAMILY_2};
  font-weight: 500;
  font-size: 28px;
`
const h3_Odp = styled.h3`
  color: ${colors.white};
  font-family: ${token.typography.family.FAMILY_2};
  font-weight: 500;
  font-size: 20px;
  margin: 0;
`

const p_Odp = styled.p`
  font-size: 14px;
  line-height: 28px;
  margin: 0px;
`

const OdpLinkButton = styled.a<{ comingSoon?: boolean }>`
  padding: 8px 35px;
  border-radius: 50px;
  border-style: none;
  background-color: ${colors.seaGreen};
  font-weight: 700;
  text-decoration: none;
  width: fit-content;
  color: ${colors.mainViolet};
  &:hover,
  &:focus {
    color: ${colors.mainViolet};
    text-decoration: none;
  }

  ${props =>
    props.comingSoon &&
    css`
      background-color: ${colors.seaGreenO30};
      color: ${colors.mainVioletO30};
      pointer-events: none;
      cursor: default;

      &:hover,
      &:focus {
        color: ${colors.mainVioletO30};
      }
    `}
`

const ComingSoonFont = css`
  font-style: italic;
  color: ${colors.mediumPurple};
  font-family: ${token.typography.family.FAMILY_2};
`

const OdpButtonComingSoon = styled.div`
  color: ${colors.mediumPurple};
  font-style: italic;
  font-family: ${token.typography.family.FAMILY_2};
  font-size: 14px;
  font-weight: 500;
  padding: 0.5rem 0.5rem;
`

export const SharedStyled = {
  h2_Odp,
  h3_Odp,
  p_Odp,
  OdpLinkButton,
  ComingSoonFont,
  OdpButtonComingSoon,
}
