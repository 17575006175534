import React from "react"
import styled from "styled-components"
import { motion } from "framer-motion"
import { OverlayTypes } from "./types"
import { token } from "../OdpGlobalComponents/tokens"
import { mediaMax } from "../OdpGlobalComponents/mixMediaQuery"
import { colors } from "../../styles/colors.style"

interface StyledProps {
  variant?: OverlayTypes
}

export const IntroTextArea = styled(motion.div)<StyledProps>`
  width: 100%;
  color: ${props => (props.variant === "stories" ? `${colors.white}` : `${colors.mainViolet}`)};
  padding-top: ${props => (props.variant === "stories" ? "118px" : 0)};
  position: sticky;
  top: 0;

  ${mediaMax.WIDTH_2} {
    position: relative;
    padding-top: 0;
  }
`

interface Props {
  title: string
  subtitle: string
  variant?: OverlayTypes
}

const IntroText = ({ title, subtitle, variant }: Props) => {
  const formattedTitle = title.split(" ")

  return (
    <IntroTextArea variant={variant}>
      <h2
        style={{
          fontSize: variant === "stories" ? "48px" : "28px",
          fontFamily: "Lora",
          fontWeight: 500
        }}>
        {formattedTitle.length > 1 ? (
          <>
            {formattedTitle[0]} <br /> {formattedTitle[1]}
          </>
        ) : (
          formattedTitle[0]
        )}
      </h2>
      <p
        style={{
          marginTop: 0,
          width: "80%",
          maxWidth: "233px",
          fontSize: "14px",
          fontFamily: `${token.typography.family.FAMILY_1}`,
        }}>
        {subtitle}
      </p>
    </IntroTextArea>
  )
}

export default IntroText
