import { css } from "styled-components";

export const GlobalPrint = css`
  @media print {
    @page {
      margin: 0 2cm 2cm 2cm;
    }

    body {
      font-family: Arial, Helvetica, sans-serif;
    }
  }
`;
