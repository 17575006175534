import { css } from "styled-components";
import { colors } from "../../../../styles/colors.style";

export const GlobalHr = css`
  hr {
    margin: 0;
    border: none;
    border-bottom: 1px solid ${colors.mainVioletO20};
  }
`;
