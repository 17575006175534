import mapboxgl from "mapbox-gl"
import {
  IDataLayerMain,
  IDataProductResult,
  IMapboxLayerType,
} from "odp-sdk/dist/types"
import { ITileset } from "../../pages/Explorer/components/MapBox/map-types"

export enum MapVariant {
  Vector = "Vector",
  Satellite = "Satellite",
}

export const initialExplorerConfig: IExplorerConfiguration = {
  activeBaseMapId: MapVariant.Vector,
  explorerFilters: {
    startDate: new Date("1900-01-01T00:00:00.000Z"),
    endDate: new Date("2020-12-31T00:00:00.000Z"),
    minDepth: 0,
    maxDepth: 11000,
  },
  mapZoom: 2,
  mapCenter: new mapboxgl.LngLat(0, 0),
  dataLoading: false,
}

export const initialExplorerState: IExplorerReduxState = {
  explorerConfiguration: initialExplorerConfig,
  mapLayers: [],
  showTutorialTooltips: false,
}

export interface IExplorerReduxState {
  explorerConfiguration: typeof initialExplorerConfig
  mapLayers: IMapLayer[]
  showTutorialTooltips: boolean
}

export interface IExplorerConfiguration {
  activeBaseMapId: MapVariant
  explorerFilters: IMapFilter
  mapZoom: number
  mapCenter: mapboxgl.LngLat
  dataLoading: boolean
}

export interface IBaseMap {
  id: string
  name: string
  url: string
}

export interface IMapLayer {
  id: number
  name: string
  iconType?: IMapboxLayerType
  info: {
    title: string
    description?: string
  }
  tileset: ITileset
}

export interface IMapFilter {
  startDate: Date
  endDate: Date
  minDepth: number
  maxDepth: number
  [key: string]: number | Date
}

export interface ICatalogFilters {
  isPrivate: boolean
  isPublic: boolean
  availableInExplorer: boolean
  availableInConnector: boolean
  gridded: boolean
  startYear?: number
  endYear?: number
}

export interface ICatalogReduxState {
  results: IDataProductResult[]
  filters: ICatalogFilters
  filteredResults: IDataProductResult[]
  explorerLayersToSelect: IDataLayerMain[]
}

export const initialCatalogState: ICatalogReduxState = {
  filters: {
    availableInConnector: false,
    availableInExplorer: false,
    isPrivate: false,
    isPublic: false,
    gridded: false,
  },
  results: [],
  filteredResults: [],
  explorerLayersToSelect: [],
}
