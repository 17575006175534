import { IDataProductMeta } from "odp-sdk/dist/types"
import React from "react"
import { S } from "./Tabs.shared.style"

type Props = {
  dataProduct: IDataProductMeta
}

const MetadataTab = ({ dataProduct }: Props) => {
  const excludedProps: (keyof IDataProductMeta)[] = [
    "uuid",
    "availableIn",
    "boundingBox",
    "timespan",
    "tags",
    "accessType",
    "homepage",
    "databaseDescription",
    "citation",
    "providerAcronym",
    "name",
  ]
  const formatCamelCaseName = (textInCC: string) => {
    const result = textInCC.replace(/([A-Z])/g, " $1")
    return result.charAt(0).toUpperCase() + result.slice(1)
  }

  return (
    <S.Tab>
      <h4>Metadata</h4>
      {Object.entries(dataProduct)
        .filter(
          el =>
            !excludedProps.includes(el[0] as keyof IDataProductMeta) && el[1]
        )
        .map(el => (
          <div key={el[0]}>
            <strong>{formatCamelCaseName(el[0])}: </strong>
            {el[1]}
          </div>
        ))}
    </S.Tab>
  )
}

export default MetadataTab
