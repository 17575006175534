import React from 'react'
import { useCatalogSearch } from '../../../catalog.helpers';
import { S } from './SearchTag.style';

type Props = {
  tagName: string,
}

const SearchTag = (props: Props) => {
  const { searchFunction } = useCatalogSearch();

  const onClick = () => searchFunction(props.tagName)

  return (
    <S.StyledTag onClick={onClick}>{props.tagName}</S.StyledTag>
  )
}



export default SearchTag