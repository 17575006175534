/**
 * This config provides utilities to change the font-size and line-height
 *
 * @category Config
 */

import { token } from "./tokens";


export const typeScale = {
  // Extra small
  PRESET_0: [
    {
      "font-size": token.typography.size.SIZE_0,
      "line-height": token.typography.lineHeight.HEIGHT_0,
    },
  ],
  // Small
  PRESET_1: [
    {
      "font-size": token.typography.size.SIZE_1,
      "line-height": token.typography.lineHeight.HEIGHT_1,
    },
  ],
  // Body / H6 / H3 Space Mono / Tertiary Button
  PRESET_2: [
    {
      "font-size": token.typography.size.SIZE_2,
      "line-height": token.typography.lineHeight.HEIGHT_2,
    },
  ],
  // H2 Space Mono
  PRESET_3: [
    {
      "font-size": token.typography.size.SIZE_3,
      "line-height": token.typography.lineHeight.HEIGHT_3,
    },
  ],
  // H5 Bold / P Large
  PRESET_4: [
    {
      "font-size": token.typography.size.SIZE_4,
      "line-height": token.typography.lineHeight.HEIGHT_4,
    },
  ],
  // Body Space Mono
  PRESET_5: [
    {
      "font-size": token.typography.size.SIZE_5,
      "line-height": token.typography.lineHeight.HEIGHT_5,
    },
  ],
  // H4 Semi-bold
  PRESET_6: [
    {
      "font-size": token.typography.size.SIZE_6,
      "line-height": token.typography.lineHeight.HEIGHT_6,
    },
  ],
  // H1 Space Mono
  PRESET_7: [
    {
      "font-size": token.typography.size.SIZE_7,
      "line-height": token.typography.lineHeight.HEIGHT_7,
    },
  ],
  // H3 Bold
  PRESET_8: [
    {
      "font-size": token.typography.size.SIZE_8,
      "line-height": token.typography.lineHeight.HEIGHT_8,
    },
  ],
  // H2 Bold
  PRESET_9: [
    {
      "font-size": token.typography.size.SIZE_9,
      "line-height": token.typography.lineHeight.HEIGHT_9,
    },
  ],
  // H1
  PRESET_10: [
    {
      "font-size": token.typography.size.SIZE_10,
      "line-height": token.typography.lineHeight.HEIGHT_10,
    },
  ],
  // H1 Large
  PRESET_11: [
    {
      "font-size": token.typography.size.SIZE_11,
      "line-height": token.typography.lineHeight.HEIGHT_11,
    },
  ],
};
