import React from "react"
import { useQuery } from "react-query"
import { useNavigate, useSearchParams } from "react-router-dom"
import { useOdpClient } from "../../../../utils/odpClient"

import AutocompleteDataSource from "../../components/AutocompleteDataSource"
import DCRefinePanel from "./components/FilterPanel"
import DCResultsList from "./components/CatalogResultsList"
import { S } from "./CatalogResults.style"
import { S as S2 } from "../../Catalog.shared.style"
import ChevronLeftIcon from "../../../../components/icons/ChevronLeftIcon"
import { useODPSelector } from "../../../../utils/helpers/useAppStateSelector"
import { getCatalogResultsAC } from "../../../../utils/redux/actions/catalogActions"
import { useDispatch } from "react-redux"
import { IDataProductResult } from "odp-sdk/dist/types"

const DataCatalogResults = () => {
  const [searchParams] = useSearchParams()
  const searchString = searchParams.get("q")
  const navigate = useNavigate()
  const odpClient = useOdpClient()
  const dispatch = useDispatch()

  const data = useODPSelector(state => state.catalog.filteredResults)

  const { isLoading } = useQuery(
    ["catalogSearchResults", searchString],
    async () => {
      if (searchString == null) return [] // checks against null & undefined but lets "" through
      let data: IDataProductResult[] = []
      if (searchString === "") {
        data = await odpClient.getAllDataProducts()
      } else {
        data = await odpClient.searchCatalog(searchString)
      }
      dispatch(getCatalogResultsAC({ results: data }))
    },
    { enabled: searchString != null }
  )

  const formattedSearchString = searchString !== "" ? `'${searchString}'` : ``
  const headline = !isLoading
    ? `${data.length} ${formattedSearchString} results`
    : "Searching..."

  const goBack = () => {
    navigate(-1)
  }

  return (
    <S2.Page>
      <div>
        <S2.SearchBar>
          <div onClick={goBack}>
            <ChevronLeftIcon />
          </div>
          <AutocompleteDataSource initialValue={searchString || undefined} />
        </S2.SearchBar>
        <h2>{headline}</h2>
        <S.ResultsWrapper>
          <DCRefinePanel />
          <DCResultsList dataSources={data} isLoading={isLoading} />
        </S.ResultsWrapper>
      </div>
    </S2.Page>
  )
}

export default DataCatalogResults
