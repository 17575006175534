import { createReducer } from "@reduxjs/toolkit"
import { AnyPaint, CirclePaint, FillPaint, LinePaint } from "mapbox-gl"
import {
  toggleLayerVariableAC,
  changeIconColorAC,
  setBaseMapAC,
  setMapZoomAC,
  setMapCenterAC,
  setExplorerFilterAC,
  toggleDataLoadingAC,
  setMapPositionAC,
  fetchAndAddLayer,
  toggleLayerVisibilityAC,
  removeLayerAC,
  toggleTutorialTooltipsAC,
} from "../actions/explorerActions"
import { initialExplorerState } from "../initialState"

const explorer = createReducer(
  () => initialExplorerState,
  builder => {
    builder
      .addCase(toggleLayerVariableAC, (state, action) => {
        const tileset = state.mapLayers.find(
          el => el.id === action.payload.layerId
        )?.tileset
        if (tileset) tileset.isVisible = !tileset?.isVisible
      })
      .addCase(toggleLayerVisibilityAC, (state, action) => {
        const layer = state.mapLayers.find(
          el => el.id === action.payload.layerId
        )
        if (layer?.tileset?.isVisible !== undefined) {
          layer.tileset.isVisible = !layer.tileset?.isVisible
        }
      })
      .addCase(removeLayerAC, (state, action) => {
        const indexLayer = state.mapLayers.findIndex(
          el => el.id === action.payload.layerId
        )
        if (indexLayer !== -1) {
          state.mapLayers = [
            ...state.mapLayers.slice(0, indexLayer),
            ...state.mapLayers.slice(indexLayer + 1),
          ]
        }
      })
      .addCase(changeIconColorAC, (state, action) => {
        const { layerId, iconColor } = action.payload
        const tilesetToChange = state.mapLayers.find(
          el => el.id === layerId
        )?.tileset
        if (tilesetToChange) {
          let paint: AnyPaint
          switch (tilesetToChange.type) {
            case "circle":
              paint = tilesetToChange.style.paint as CirclePaint
              paint["circle-color"] = iconColor
              break
            case "fill":
              paint = tilesetToChange.style.paint as FillPaint
              paint["fill-color"] = iconColor
              break
            case "line":
              paint = tilesetToChange.style.paint as LinePaint
              paint["line-color"] = iconColor
              break
          }
        }
      })
      .addCase(setBaseMapAC, (state, action) => {
        state.explorerConfiguration.activeBaseMapId = action.payload.baseMapId
      })
      .addCase(setExplorerFilterAC, (state, action) => {
        state.explorerConfiguration.explorerFilters[action.payload.key] =
          action.payload.value
      })
      .addCase(setMapZoomAC, (state, action) => {
        state.explorerConfiguration.mapZoom = action.payload.mapZoom
      })
      .addCase(setMapPositionAC, (state, action) => {
        state.explorerConfiguration.mapZoom = action.payload.mapZoom
        state.explorerConfiguration.mapCenter = action.payload.mapCenter
      })
      .addCase(setMapCenterAC, (state, action) => {
        state.explorerConfiguration.mapCenter = action.payload.mapCenter
      })
      .addCase(toggleTutorialTooltipsAC, state => {
        state.showTutorialTooltips = !state.showTutorialTooltips
      })
      .addCase(toggleDataLoadingAC, state => {
        state.explorerConfiguration.dataLoading =
          !state.explorerConfiguration.dataLoading
      })
      .addCase(fetchAndAddLayer.fulfilled, (state, action) => {
        state.mapLayers.push(action.payload.mapLayer)
      })
  }
)

export default explorer
