import log from "loglevel"
import { useCallback, useState, useEffect } from "react"
import { appInsights } from "./AppInsights"
import { useODPDispatch, useODPSelector } from "./helpers/useAppStateSelector"
import { useOdpClient } from "./odpClient"
import { setUserInfoAC } from "./redux/actions/userActions"
import { IUserInfo } from "./redux/reducers/user"

export enum AuthorizedGroups {
  ODP_DEFAULT_ACCESS_GROUP = "82b38cd3-3de8-4901-aca8-63d64b924701",
  ODP_GCP_DEFAULT_ACCESS_GROUP = "27d04248-2ca5-45ee-903c-2d89a7652b5a",
  ODP_INTERNAL_READ_ALL = "e8c71084-e2e2-4bd8-bdf7-86f9e2154ed3",
  ODC_ACCESS_DASKHUB_USERS = "8fa722ae-ff94-482a-b908-5df3aee7b233",
  APP_LUSEDATA_READER = "a3cfb328-a92d-461c-82e3-e780c26fc03a",
  APP_VESSEL_EMISSIONS_READER = "82a810d9-d301-4057-b1d0-b1508636b25a",
  APP_VESSEL_EMISSION_SIMULATOR_READER = "91c5c2ae-1932-4625-ae37-bc8db1b09626",
}

export const useAuth = (): {
  authenticated: boolean
  hasUserAuthorization: (groups: AuthorizedGroups[]) => boolean
} => {
  const dispatch = useODPDispatch()
  const odpClient = useOdpClient()

  const { authenticated, authorizedGroups } = useODPSelector(state => ({
    authenticated: state.user.authenticated,
    authorizedGroups: state.user.authorizedGroups,
  }))

  // parse id and access tokens
  const onToken = useCallback(
    (token: typeof odpClient.authTokens) => {
      if (!token) return

      const idTokenClaims = token?.idTokenClaims
      if (!idTokenClaims) {
        dispatch({ type: "RESET_USER_STATE" })
        appInsights.clearAuthenticatedUserContext()
        return
      }

      const authenticatedUserId = idTokenClaims.sub.replace(/[,;=| ]+/g, "_")
      appInsights.setAuthenticatedUserContext(authenticatedUserId)

      const userInfo: IUserInfo = {
        id: idTokenClaims.sub, // sub = subject
        email: idTokenClaims.email,
        name: idTokenClaims.name,
        firstName: idTokenClaims.given_name,
        lastName: idTokenClaims.family_name,
        groups: idTokenClaims.groups,
      }
      const authorizedGroups = idTokenClaims.groups.filter(group =>
        Object.values(AuthorizedGroups).includes(group as AuthorizedGroups)
      )
      dispatch(
        setUserInfoAC({
          userInfo: userInfo,
          authorizedGroups: authorizedGroups as Array<AuthorizedGroups>,
        })
      )
    },
    [dispatch, odpClient]
  )

  const hasUserAuthorization = (groups: AuthorizedGroups[]) => {
    return groups.some(group => {
      return authorizedGroups.includes(group)
    })
  }

  // Initialize auth by triggering the SDK to get an access_token
  const [authInitialized, set_authInitialized] = useState(false)
  useEffect(() => {
    if (authenticated) {
      return
    }

    odpClient
      .authenticate()
      .then(() => {
        set_authInitialized(true)
        onToken(odpClient.authTokens)
      })
      .catch(err => {
        log.error("Initial authentication failed", err)
      })
  }, [authenticated, odpClient, onToken])

  // Subscribe to changes in the idToken/userInfo
  useEffect(() => {
    if (!authInitialized) {
      return
    }

    const unsubscribe = odpClient.listenToAuthChanges(onToken)
    return () => {
      unsubscribe()
    }
  }, [authInitialized, odpClient, onToken])

  return { authenticated, hasUserAuthorization }
}
