import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { faMapMarkedAlt } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { useNavigate } from "react-router-dom"
import { S } from "./SearchTile.style"

type Props = {
  text: string,
  id: string
}

const SearchTile = (props: Props) => {
  const navigate = useNavigate()
  const onClick = () => {
    navigate(`/catalog/product/${props.id}`)
  }

  const getIconComponent = (tileName: string) => {
    return <FontAwesomeIcon icon={faMapMarkedAlt as IconProp} size="2x" />
  }

  return (
    <S.StyledTile onClick={onClick}>
      {getIconComponent(props.text)}
      <span>{props.text}</span>
    </S.StyledTile>
  )
}

export default SearchTile
