import { IDataProductResult, OdpDataAccessSolutions } from "odp-sdk/dist/types"
import React from "react"
import { useDispatch } from "react-redux"
import { Link, useSearchParams } from "react-router-dom"
import { setExplorerLayersToSelectAC } from "../../../../../utils/redux/actions/catalogActions"
import { Styled } from "./CatalogResult.style"

type Props = {
  dataSource: IDataProductResult
}

function DataCatalogResultEntry({ dataSource }: Props) {
  const { dataProductResult, dataLayersMain } = dataSource
  const [searchParams] = useSearchParams()
  const dispatch = useDispatch()
  const searchString = searchParams.get("q")
  const {
    availableIn,
    providerAcronym,
    uuid,
    name,
    databaseDescription,
    tags,
  } = dataProductResult

  const onClick = () => {
    dispatch(setExplorerLayersToSelectAC({ layers: dataLayersMain }))
  }

  const addExButtonVisible =
    availableIn.includes(OdpDataAccessSolutions.ODE) && !!dataLayersMain.length

  return (
    <Styled.ResultEntry>
      <div>
        <Styled.ProviderTag>
          {providerAcronym && providerAcronym.join(", ")}
        </Styled.ProviderTag>
        <h3>
          <Link to={`/catalog/product/${uuid}`} state={{ searchString }}>
            {name.toUpperCase()}
          </Link>
        </h3>
        <p>
          {databaseDescription &&
            databaseDescription
              .split(new RegExp(`(${searchString})`))
              .map((el: string, i: number) => {
                if (el === searchString)
                  return <strong key={"description tag " + el + i}>{el}</strong>
                return el
              })}
        </p>
        <p>
          {tags.map((el: string, i: number) => {
            const tagToDisplay = i === tags.length - 1 ? el + "." : el + ", "
            if (el === searchString)
              return (
                <strong key={"desc_tag" + el + i}>
                  <em>{tagToDisplay}</em>
                </strong>
              )
            return <em key={"desc_tag" + el + i}>{tagToDisplay}</em>
          })}
        </p>
      </div>
      <Styled.AddButtonContainer>
        {addExButtonVisible && (
          <Styled.AddButton onClick={onClick}>add to explorer</Styled.AddButton>
        )}
      </Styled.AddButtonContainer>
    </Styled.ResultEntry>
  )
}

export default DataCatalogResultEntry
