import styled from "styled-components"

import { colors } from "../../styles/colors.style"
import { token } from "../../components/OdpGlobalComponents/tokens"

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`

const Content = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    font-family: ${token.typography.family.FAMILY_2};
    font-weight: 400;
    margin: 0;
    line-height: 100px;
  }

  img {
    max-width: 640px;
    width: 100%;
    height: auto;
  }

  p {
    line-height: 32px;
  }
`

const JoinButton = styled.a`
  color: inherit;
  height: 40px;
  border-radius: 26px;
  padding: 26px 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  background-color: ${colors.seaGreen};
  font-weight: 700;
  text-decoration: none;
  margin-top: 12px;
  &:hover {
    color: inherit;
  }
`

const Header = styled.div`
  background-color: ${colors.mainViolet};

  img {
    padding: 18px 32px;
    box-sizing: content-box;
  }
`

export const Styled = {
  Wrapper,
  Content,
  JoinButton,
  Header
}
