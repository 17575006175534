import styled from "styled-components"
import { motion } from "framer-motion"

export const ListItemGrid = styled(motion.div)`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  grid-auto-rows: minmax(374px, auto);
  padding-bottom: 30px;
`
