import "@fontsource/roboto/700.css"
import "@fontsource/roboto/400.css"
import styled from "styled-components"
import { token } from "../../../components/OdpGlobalComponents/tokens"

const ConnectorPackagesContentWrapper = styled.div`
  padding: 0rem 4rem 0rem 4rem;
  p {
    font-size: 14px;
    line-height: 24px;
  }
`

const ConnectorPackagesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 3rem;
`

const ConnectorPackageWrapper = styled.div`
  font-family: ${token.typography.family.FAMILY_1};
  font-size: 14px;
  line-height: 24px;
  margin-right: 4rem;
  padding-bottom: 3rem;
  width: 270px;
`
const ConnectorPackageHeadline = styled.div`
  font-weight: 700;
`
const ConnectorPackageDescription = styled.div`
  font-weight: 400;
  padding-bottom: 1rem;
`
const ConnectorPackageLink = styled.a`
  text-decoration: none;
  color: black;
  font-weight: 700;
  &:first-of-type {
    padding-right: 0.5rem;
  }
  &:last-of-type {
    padding-left: 0.5rem;
  }
  &:hover {
    color: black;
  }
`

export const S = {
  ConnectorPackagesContentWrapper,
  ConnectorPackageWrapper,
  ConnectorPackageHeadline,
  ConnectorPackageDescription,
  ConnectorPackageLink,
  ConnectorPackagesWrapper,
}
