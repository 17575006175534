import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';

import { GlobalBlockquote } from './styles/blockquot.globalstyle';
import { GlobalBody } from './styles/body.globalstyle';
import { GlobalFocus } from './styles/focus.globalstyle';
import { GlobalHeading } from './styles/heading.globalstyle';
import { GlobalHr } from './styles/hr.globalstyle';
import { GlobalLink } from './styles/link.globalstyle';
import { GlobalParagraph } from './styles/paragraph.globalstyle';
import { GlobalPrint } from './styles/print.globalstyle';
import { GlobalSmall } from './styles/small.globalstyle';

require("typeface-space-mono");
require("typeface-roboto");
require("typeface-lora");

export const GlobalStyle = createGlobalStyle`
  /** Normalize */
  ${normalize}

  /** Body */
  ${GlobalBody}

  /** Bloackquote */
  ${GlobalBlockquote}

  /** Typography */
  ${GlobalHeading}
  ${GlobalParagraph}
  ${GlobalSmall}

  /** Navigation */
  ${GlobalLink}

  /** Focus */
  ${GlobalFocus}

  /** Horizontal Ruler */
  ${GlobalHr}

  /** Print */
  ${GlobalPrint}
`;
