import mapboxgl from "mapbox-gl"
import {
  IDataLayerMain,
  IDataProductStyling,
  IMapboxLayerType,
} from "odp-sdk/dist/types"
import { IMapLayer } from "../redux/initialState"
import { getODPClient } from "../odpClient"

const defaultMapboxStyle = {
  "circle-color": "#58FCD4",
  "circle-radius": 3,
  "circle-stroke-width": 1,
  "circle-stroke-color": "#ffffff",
  "fill-color": "#98D5A8",
  "fill-opacity": 0.5,
  "line-width": 1,
  "line-color": "#8CA5EC",
}

export const createMapboxPaint = (
  styleType: IMapboxLayerType,
  dataStyle?: IDataProductStyling
): mapboxgl.AnyPaint => {
  switch (styleType) {
    case "circle": {
      return {
        "circle-color": dataStyle?.color || defaultMapboxStyle["circle-color"],
        "circle-radius":
          dataStyle?.circleRadius || defaultMapboxStyle["circle-radius"],
        "circle-stroke-width":
          dataStyle?.strokeWidth || defaultMapboxStyle["circle-stroke-width"],
        "circle-stroke-color":
          dataStyle?.strokeColor || defaultMapboxStyle["circle-stroke-color"],
      }
    }
    case "fill": {
      return {
        "fill-color": dataStyle?.color || defaultMapboxStyle["fill-color"],
        "fill-opacity":
          dataStyle?.opacity || defaultMapboxStyle["fill-opacity"],
      }
    }
    case "line": {
      return {
        "line-width":
          dataStyle?.strokeWidth || defaultMapboxStyle["line-width"],
        "line-color":
          dataStyle?.strokeColor || defaultMapboxStyle["line-color"],
      }
    }
    default:
      return defaultMapboxStyle
  }
}

/**
 * Generates object with data necessary for displaying data in the explorer
 * @param searchResult :ISearchResult
 * @returns IMapLayer
 */
export const createMapLayer = async (
  mapLayer: IDataLayerMain
): Promise<IMapLayer> => {
  const odpClient = getODPClient()
  const layer = await odpClient.getDataLayerById(mapLayer.id)
  const result: IMapLayer = {
    id: layer.id,
    info: {
      title: layer.name,
      description: layer.name,
    },
    name: layer.name,
    iconType: layer.layerType,
    tileset: {
      name: layer.name,
      unit_abbr: layer.unit_abbr || "",
      isVisible: true,
      variable: layer.name,
      filters: layer.filter || [],
      source: {
        url: layer.sourceUrl,
      },
      type: layer.layerType,
      style: {
        paint: createMapboxPaint(layer.layerType, layer.explorerStyling),
      },
    },
  }

  return result
}
