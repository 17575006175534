import { createGlobalStyle } from "styled-components"
import { mediaMin } from "../../mixMediaQuery"

interface IProps {
  /** Fixed header */
  fixedHeader?: boolean | undefined
}

export const GlobalLayoutStyle = createGlobalStyle<IProps>`
  body {
    padding: ${props => (props.fixedHeader ? "4rem 0 0 0" : "0")};

    ${mediaMin.WIDTH_2} {
      padding: ${props => (props.fixedHeader ? "6rem 0 0 0" : "0")};
    }

    ${mediaMin.WIDTH_3} {
      padding: ${props => (props.fixedHeader ? "7rem 0 0 0" : "0")};
    }
  }
`
