import { IDataLayerMain, IDataProductMeta } from "odp-sdk/dist/types"
import React from "react"
import { MetadataSection } from "../MetadataDetails"
import CitationTab from "./CitationTab"
import DataAccessTab from "./DataAccessTab"
import DocumentationTab from "./DocumentationTab"
import MetadataTab from "./MetaDataTab"

type Props = {
  sectionName: MetadataSection
  dataProduct: IDataProductMeta
  explorerLayers?: IDataLayerMain[]
}

const TabsSwitch = (props: Props) => {
  switch (props.sectionName) {
    case "documentation":
      return <DocumentationTab dataProduct={props.dataProduct} />
    case "metadata":
      return <MetadataTab dataProduct={props.dataProduct} />
    case "data access":
      return (
        <DataAccessTab
          dataProduct={props.dataProduct}
          explorerLayers={props.explorerLayers}
        />
      )
    case "citation":
      return <CitationTab dataProduct={props.dataProduct} />
  }
}

export default TabsSwitch
