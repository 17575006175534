import React, { useState } from "react"

import FilterHeader from "../../components/Sidebar/FilterHeader"
import OverlayViewContainer from "../../components/Sidebar/OverlayViewContainer"
import AppList from "./components/AppList"

interface Props {
  isOpen: boolean
}

const AppView = ({ isOpen }: Props) => {
  const [searchQuery, setQuery] = useState("")
  const [category, setCategory] = useState("")

  const categoryItems = ["All", "Aquaculture", "Shipping", "Governance"]

  return (
    <OverlayViewContainer
      leftColumnTitle={"Apps"}
      leftColumnSubtitle={
        "Browse a collection of recommended tools which solve ocean problems and are derived from data from the Ocean Data Platform."
      }
      rightColumnContent={
        <>
          <FilterHeader
            selectedCategory={category ? category : "All"}
            categories={categoryItems}
            searchQuery={searchQuery}
            updateQuery={setQuery}
            updateCategory={setCategory}
          />
          <AppList searchQuery={searchQuery} selectedCategory={category} />
        </>
      }
      isOpen={isOpen}
      keyString={"appContainer"}
    />
  )
}

export default AppView
