import styled from "styled-components"
import { mediaMin } from "../../components/OdpGlobalComponents/mixMediaQuery"
import { token } from "../../components/OdpGlobalComponents/tokens"
import { colors } from "../../styles/colors.style"
import { StyledPage } from "../../styles/shared.style"

const Page = styled(StyledPage)`
  position: relative;
  padding: 0 ${token.space.SPACING_14};
  display: flex;
  flex-direction: column;
  height: 100vh;

  ${mediaMin.WIDTH_2} {
    padding: 0 ${token.space.SPACING_20};
  }

  ${mediaMin.WIDTH_3} {
    padding: 0 5.25rem;
  }

  @media (min-width: 1800px) {
    width: calc(100vw - 5.25rem);

    > div {
      position: relative;
      width: 1500px;
      margin: 0 auto;
    }
  }

  h2 {
    font-size: ${token.typography.size.SIZE_7};
    line-height: ${token.typography.lineHeight.HEIGHT_7};
    font-weight: ${token.typography.weight.WEIGHT_3};
    font-family: ${token.typography.family.FAMILY_2};
    margin-bottom: ${token.space.SPACING_4};
  }
`

const SearchBar = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: ${token.space.SPACING_11} 0;
  max-width: 500px;

  > div:first-child {
    left: -30px;
    position: absolute;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
`

const StyledTag = styled.span`
  padding: 0 16px;
  border: 1px solid ${colors.mainVioletO30};
  border-radius: 13px;
  display: flex;
  align-items: center;
  height: 26px;
  text-transform: uppercase;
  font-size: 12px;
  color: ${colors.mainVioletO50};
  font-weight: 700;
`

const Button = styled.button`
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 15px;
  box-sizing: border-box;
  height: 30px;
`

const WideButton = styled(Button)`
  font-size: 12px;
  text-transform: uppercase;
  padding: 0 15px;
  line-height: 30px;
  white-space: nowrap;
`

const AddButtonGray = styled(WideButton)`
  color: white;
  background-color: ${colors.mainVioletO50};
  &:hover {
    background-color: ${colors.mainVioletO30};
  }
  transition: background-color 200ms;
`

export const S = {
  SearchBar,
  Page,
  StyledTag,
  Button,
  WideButton,
  AddButtonGray
}
