import { IDataProductResult } from "odp-sdk/dist/types"
import React from "react"
import { colors } from "../../../../../styles/colors.style"
import { Loader } from "../../../../../components/Loader/Loader"
import AddLayersToExplorerModal from "./AddLayersModal/AddLayersToExplorerModal"
import DataCatalogResultEntry from "./CatalogResult"
import { S } from "./CatalogResultsList.style"

type Props = {
  dataSources?: IDataProductResult[]
  isLoading: boolean
}

const DCResultsList = ({ dataSources, isLoading }: Props) => {
  if (isLoading)
    return (
      <S.Results>
        <S.LoaderWrapper>
          <Loader color={colors.mainVioletO50} />
        </S.LoaderWrapper>
      </S.Results>
    )

  if (!dataSources?.length)
    return (
      <S.Results>
        <div>
          <p style={{ marginTop: 0 }}>Your search did not match any results.</p>
          <div>
            Suggestions:
            <ul style={{ margin: 0 }}>
              <li>Make sure that all words are spelled correctly.</li>
              <li>Try different keywords.</li>
            </ul>
          </div>
        </div>
      </S.Results>
    )

  return (
    <>
      <S.Results>
        {dataSources.map(el => (
          <DataCatalogResultEntry
            dataSource={el}
            key={"catalogResult" + el.dataProductResult.uuid}
          />
        ))}
      </S.Results>
      <AddLayersToExplorerModal />
    </>
  )
}

export default DCResultsList
