import { Checkbox } from "@mui/material"
import { IDataLayerMain } from "odp-sdk/dist/types"
import React from "react"
import { useDispatch } from "react-redux"
import { useODPSelector } from "../../../../../../utils/helpers/useAppStateSelector"
import {
  fetchAndAddLayer,
  removeLayerAC,
} from "../../../../../../utils/redux/actions/explorerActions"
import { S } from "../FilterPanel.style"

type Props = {
  dataLayer: IDataLayerMain
}

const AddLayer = ({ dataLayer }: Props) => {
  const isDisplayedInExplorer = useODPSelector(
    state =>
      state.explorer.mapLayers.findIndex(el => el.id === dataLayer.id) > -1
  )
  const dispatch = useDispatch()

  const odClick = () => {
    if (isDisplayedInExplorer)
      dispatch(removeLayerAC({ layerId: dataLayer.id }))
    else dispatch(fetchAndAddLayer(dataLayer))
  }

  return (
    <S.FormControlLabel
      control={<Checkbox checked={isDisplayedInExplorer} onClick={odClick} />}
      label={dataLayer.name}
      key={"chckbx" + dataLayer.id}
    />
  )
}

export default AddLayer
