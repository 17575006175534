import React from "react"
import { S } from "./ComingSoon.style"
import { SharedStyled } from "../../styles/shared.style"

type Props = {
  paragraphs: string[];
  imageDescription: string;
  title: string;
  imageSource: string;
}

const ComingSoon = (props: Props) => {
  return (
    <S.ViewWrapper>
      <S.LeftPanel>
        <SharedStyled.h2_Odp>{props.title}</SharedStyled.h2_Odp>
        <span>Coming soon</span>
        {props.paragraphs.map((el, i) => <p key={'paragraph_' + el.substring(5) + i}>{el}</p>)}
      </S.LeftPanel>
      <S.Content>
        <S.Preview>
          <SharedStyled.h2_Odp>Coming soon</SharedStyled.h2_Odp>
          <div>
            <img src={props.imageSource} />
          </div>
          <SharedStyled.h2_Odp>
            {props.imageDescription}
          </SharedStyled.h2_Odp>
        </S.Preview>
      </S.Content>
    </S.ViewWrapper>
  )
}

export default ComingSoon
