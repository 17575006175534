import { styled, Tab, Tabs } from "@mui/material"
import { token } from "../../../components/OdpGlobalComponents/tokens"
import { colors } from "../../../styles/colors.style"

const ConnectorTabs = styled(Tabs)`
  padding-top: 6rem;
  .MuiTabs-indicator {
    display: none;
  }
` as typeof Tabs

const ConnectorTab = styled(Tab)`
  align-items: start;
  text-align: start;
  font-size: 1rem;
  color: ${colors.mainVioletO50};
  padding: 1.5rem 0rem 1.5rem 2rem;
  font-weight: 700;

  &:first-of-type {
    border-top: 1px solid ${colors.mainVioletO30};
  }
  border-bottom: 1px solid ${colors.mainVioletO30};

  &.Mui-selected {
    color: ${colors.mainViolet};
  }

  &.Mui-disabled {
    color: ${colors.mainVioletO15};
    display: inline-block;

    &:after {
      content: "Coming soon";
      font-style: italic;
      color: ${colors.mediumPurple};
      font-family: ${token.typography.family.FAMILY_2};
      font-size: 14px;
      text-transform: none;
      font-weight: normal;
      display: inline-block;
      margin-left: 10px;
    }
  }
` as typeof Tab

export const S = {
  ConnectorTabs,
  ConnectorTab,
}
