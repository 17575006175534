import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';
import { colors } from '../../styles/colors.style';
import { mediaMax } from '../OdpGlobalComponents/mixMediaQuery';
import { token } from '../OdpGlobalComponents/tokens';

import IntroText from './IntroText';
import { OverlayTypes } from './types';

interface ContainerProps {
  backgroundColor?: string
}

const OverlayContainer = styled(motion.section)<ContainerProps>`
  height: 100vh;
  width: calc(100vw - 84px);
  background-color: ${props =>
    props.backgroundColor
      ? props.backgroundColor
      : colors.white};
  position: absolute;
  padding: 30px 60px;
  top: 0;
  right: 0;
  float: right;
  overflow: auto;

  ${mediaMax.WIDTH_3} {
    padding: 30px;
    width: calc(100vw - 80px);
  }

  @media (max-width: 767px) {
    width: calc(100vw - 56px);
  }
`

const OverlayContent = styled(motion.div)`
  width: 100%;
  max-width: 2000px;
`

const ContentRow = styled(motion.div)`
  display: flex;
  width: 100%;
  height: 100%;

  ${mediaMax.WIDTH_2} {
    flex-direction: column;
  }
`

interface ColumnProps {
  width: string
}

const ContentColumn = styled(motion.div)<ColumnProps>`
  width: ${props => props.width};
  min-height: 100%;
  padding-bottom: 30px;

  ${mediaMax.WIDTH_2} {
    width: 100%;
  }
`

interface Props {
  leftColumnTitle: string
  leftColumnSubtitle: string
  rightColumnContent: React.ReactNode
  isOpen: boolean
  backgroundColor?: string
  storyIsOpen?: boolean
  variant?: OverlayTypes
  keyString: string
}

const containerVariants = {
  hidden: {
    x: "-110%",
  },
  visible: {
    x: 0,
    transition: {
      delayChildren: 0.4,
      staggerChildren: 0.3,
      ease: "easeInOut",
    },
  },
}

const columnVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      delay: 0.3,
    },
  },
}

const OverlayViewContainer = ({
  leftColumnTitle,
  leftColumnSubtitle,
  rightColumnContent,
  isOpen,
  backgroundColor,
  variant,
  keyString,
}: Props) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <OverlayContainer
          initial={"hidden"}
          exit={"hidden"}
          animate={"visible"}
          variants={containerVariants}
          backgroundColor={backgroundColor}
          key={`${keyString}Container`}>
          <OverlayContent key={`${keyString}Content`}>
            <ContentRow>
              <ContentColumn
                width={"22%"}
                variants={columnVariants}
                key={"column1"}
                initial={"hidden"}
                exit={"hidden"}
                animate={"visible"}>
                <IntroText
                  variant={variant}
                  title={leftColumnTitle}
                  subtitle={leftColumnSubtitle}
                />
              </ContentColumn>
              <ContentColumn
                width={"78%"}
                variants={columnVariants}
                key={"column2"}
                initial={"hidden"}
                exit={"hidden"}
                animate={"visible"}>
                {rightColumnContent}
              </ContentColumn>
            </ContentRow>
          </OverlayContent>
        </OverlayContainer>
      )}
    </AnimatePresence>
  )
}

export default OverlayViewContainer
