import React from "react"
import { motion } from "framer-motion"

//NOTE: This icon is animated and will rotate when the isOpen prop is set to true

interface Props {
  isOpen: boolean
}

const ChevronUpIcon = ({ isOpen }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6"
      fill="none"
      viewBox="0 0 24 24"
      height={"24"}
      width={"24"}
      stroke="#0A1530">
      <motion.path
        animate={isOpen ? { rotate: 0 } : { rotate: 180 }}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M5 15l7-7 7 7"
      />
    </svg>
  )
}

export default ChevronUpIcon
