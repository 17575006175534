import React from "react"
import projectsPreviewSource from "../../assets/img/projects-preview.png"
import ComingSoon from "../../components/ComingSoonPage/ComingSoon"

const ProjectsComingSoon = () => {
  return (
    <ComingSoon 
      title="Projects"
      paragraphs={[
        "Projects are saved data collections from the Explorer.",
        "Soon you will be able to save and share data collections.",
        "Currently this feature is under development."
      ]}
      imageSource={projectsPreviewSource}
      imageDescription={"Save and share themed data collections."}
    />
  )
}

export default ProjectsComingSoon
