import * as React from "react"
import { BaseCSS } from "styled-bootstrap-grid"
import { GlobalStyle } from "../../global-styles/GlobalStyle"

import { GlobalLayoutStyle } from "./Layout.style"

export interface IProps {
  /** Children */
  children: React.ReactNode
  /** Fixed header */
  fixedHeader?: boolean | undefined
}

export const Layout: React.FC<IProps> = ({ children, fixedHeader }) => {
  return (
    <>
      <BaseCSS />
      <GlobalStyle />
      <GlobalLayoutStyle fixedHeader={fixedHeader} />
      {children}
    </>
  )
}
