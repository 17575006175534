import loadable from "@loadable/component"
import { useAppInsightsContext, useTrackEvent } from "@microsoft/applicationinsights-react-js"
import * as React from "react"
import { useEffect } from "react"
import { Navigate, Route, Routes } from "react-router-dom"
import AppView from "./pages/Apps/Apps"
import WelcomePage from "./pages/WelcomePage/WelcomePage"
import DataCatalog from "./pages/Catalog/pages/CatalogSearch/CatalogSearch"
import DataCatalogResults from "./pages/Catalog/pages/CatalogResults/CatalogResults"
import MetadataDetails from "./pages/Catalog/pages/MetadataDetails/MetadataDetails"
import Connector from "./pages/Connector/Connector"
import ProjectsComingSoon from "./pages/Projects/Projects"
import StoriesComingSoon from "./pages/Stories/Stories"
import AccessDeniedPage from "./pages/AccessDeniedPage/AccessDeniedPage"
import NotFoundPage from "./pages/NotFoundPage/NotFoundPage"
import { AuthorizedGroups, useAuth } from "./utils/useAuth"
import { Layout } from "./components/OdpGlobalComponents/components/Layout/Layout"

const Sidebar = loadable(() => import("./components/Sidebar/Sidebar"))
const Explorer = loadable(() => import("./pages/Explorer/Explorer"))

const App = () => {
  const appInsights = useAppInsightsContext()
  const trackAppInit = useTrackEvent(appInsights, "App Init", null)

  const { authenticated, hasUserAuthorization } = useAuth()
  const authorized = hasUserAuthorization([
    AuthorizedGroups.ODP_DEFAULT_ACCESS_GROUP,
    AuthorizedGroups.ODP_GCP_DEFAULT_ACCESS_GROUP,
  ])

  useEffect(() => {
    trackAppInit(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!authenticated) return <WelcomePage />
  if (!authorized) return <AccessDeniedPage />

  return (
    <Layout>
      <Sidebar />
      <Routes>
        <Route path="/" element={<Navigate replace to="/explorer" />} />
        <Route path="catalog/search" element={<DataCatalogResults />} />
        <Route path="catalog/product/:id" element={<MetadataDetails />} />
        <Route path="catalog" element={<DataCatalog />} />
        <Route path="explorer" element={<Explorer />} />
        <Route path="projects" element={<ProjectsComingSoon />} />
        <Route path="stories" element={<StoriesComingSoon />} />
        <Route path="apps" element={<AppView isOpen={true} />} />
        <Route path="connector" element={<Connector />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Layout>
  )
}

export default App
