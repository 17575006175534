import styled, { keyframes } from "styled-components"

type Props = {
  color?: string
}

const Loader = styled.div`
  width: 100px;
  height: 100px;
  position: relative;
`

const rotation = keyframes`
  from {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const LoaderCircle = styled.div<Props>`
  width: 100px;
  height: 100px;
  border-radius: 100%;
  border: 1px solid ${props => props.color || "white"};
  animation: ${rotation} 1s linear infinite;
  transition: 2s;
  border-bottom: none;
  border-right: none;
  left: 0;
  top: 0;
  position: absolute;

  &:nth-child(2) {
    width: 90px;
    height: 90px;
    margin: 5px;
    animation-delay: 0.2s;
  }

  &:nth-child(3) {
    margin: 10px;
    width: 80px;
    height: 80px;
    animation-delay: 0.4s;
  }

  &:nth-child(4) {
    margin: 15px;
    width: 70px;
    height: 70px;
    animation-delay: 0.6s;
  }

  &:nth-child(5) {
    margin: 20px;
    width: 60px;
    height: 60px;
    animation-delay: 0.8s;
  }
`

export const S = {
  Loader,
  LoaderCircle
}
