import mapboxgl from "mapbox-gl"
import { createAction, createAsyncThunk } from "@reduxjs/toolkit"
import { IMapLayer, MapVariant } from "../initialState"
import { IDataLayerMain } from "odp-sdk/dist/types"
import { createMapLayer } from "../../helpers/dataDisplayHelpers"

export enum ExplorerActionNames {
  TOGGLE_LAYER_VARIABLE = "TOGGLE_LAYER_VARIABLE",
  REMOVE_LAYER = "REMOVE_LAYER",
  TOGGLE_LAYER_VISIBILITY = "TOGGLE_LAYER_VISIBILITY",
  CHANGE_LEGEND_ICON_COLOR = "CHANGE_LEGEND_ICON_COLOR",
  SET_BASE_MAP = "SET_BASE_MAP",
  SET_EXPLORER_FILTER = "SET_EXPLORER_FILTER",
  SET_MAP_ZOOM = "SET_MAP_ZOOM",
  SET_MAP_POSITION = "SET_MAP_POSITION",
  SET_MAP_CENTER = "SET_MAP_CENTER",
  TOGGLE_DATA_LOADING = "TOGGLE_DATA_LOADING",
  ADD_MAP_LAYER = "ADD_MAP_LAYER",
  FETCH_STYLE_AND_ADD_MAP_LAYER = "FETCH_STYLE_AND_ADD_MAP_LAYER",
  TOGGLE_TUTORIAL_TOOLTIPS = "TOGGLE_TUTORIAL_TOOLTIPS",
}

export const toggleLayerVariableAC = createAction<{ layerId: number }>(
  ExplorerActionNames.TOGGLE_LAYER_VARIABLE
)
export const toggleTutorialTooltipsAC = createAction(
  ExplorerActionNames.TOGGLE_TUTORIAL_TOOLTIPS
)
export const removeLayerAC = createAction<{ layerId: number }>(
  ExplorerActionNames.REMOVE_LAYER
)
export const toggleLayerVisibilityAC = createAction<{ layerId: number }>(
  ExplorerActionNames.TOGGLE_LAYER_VISIBILITY
)
export const changeIconColorAC = createAction<{
  layerId: number
  iconColor: string
}>(ExplorerActionNames.CHANGE_LEGEND_ICON_COLOR)

export const setBaseMapAC = createAction<{ baseMapId: MapVariant }>(
  ExplorerActionNames.SET_BASE_MAP
)
export const setExplorerFilterAC = createAction<{
  key: string
  value: number | Date
}>(ExplorerActionNames.SET_EXPLORER_FILTER)
export const setMapZoomAC = createAction<{ mapZoom: number }>(
  ExplorerActionNames.SET_MAP_ZOOM
)
export const setMapPositionAC = createAction<{
  mapZoom: number
  mapCenter: mapboxgl.LngLat
}>(ExplorerActionNames.SET_MAP_POSITION)
export const setMapCenterAC = createAction<{ mapCenter: mapboxgl.LngLat }>(
  ExplorerActionNames.SET_MAP_CENTER
)
export const toggleDataLoadingAC = createAction(
  ExplorerActionNames.TOGGLE_DATA_LOADING
)
export const addMapLayerAC = createAction<{ mapLayer: IMapLayer }>(
  ExplorerActionNames.ADD_MAP_LAYER
)
export const fetchAndAddLayer = createAsyncThunk<
  { mapLayer: IMapLayer },
  IDataLayerMain
>(
  ExplorerActionNames.FETCH_STYLE_AND_ADD_MAP_LAYER,
  async (dataSource: IDataLayerMain) => {
    return { mapLayer: await createMapLayer(dataSource) }
  }
)
