import React from "react"

const SearchIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25">
      <path
        stroke="#200A3A"
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="1.172"
        d="M24.14 24.14l-5.175-5.175M10.344 19.828a9.484 9.484 0 100-18.969 9.484 9.484 0 000 18.97zM3.879 10.344a6.465 6.465 0 016.465-6.465"
      />
    </svg>
  )
}

export default SearchIcon
