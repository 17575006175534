import {
  AppInsightsContext,
  AppInsightsErrorBoundary,
} from "@microsoft/applicationinsights-react-js"
import log from "loglevel"
import * as React from "react"
import * as ReactDOM from "react-dom"
import { Provider } from "react-redux"
import { QueryClient, QueryClientProvider } from "react-query"
import { BrowserRouter } from "react-router-dom"

import App from "./App"
import { reactPlugin } from "./utils/AppInsights"

import { store } from "./utils/redux/store"

// Use log.debug() etc. instead of console.log(), to make logs only appear outside of prod
log.setLevel(
  process.env.NODE_ENV !== "production" ? log.levels.DEBUG : log.levels.SILENT
)

const queryClient = new QueryClient()

ReactDOM.render(
  <AppInsightsErrorBoundary
    onError={() => <h1>I believe something went wrong</h1>}
    appInsights={reactPlugin}>
    <AppInsightsContext.Provider value={reactPlugin}>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </Provider>
      </QueryClientProvider>
    </AppInsightsContext.Provider>
  </AppInsightsErrorBoundary>,
  document.getElementById("root")
)
