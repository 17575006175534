import { IDataProduct } from "odp-sdk/dist/types"
import React, { useState } from "react"
import { useQuery } from "react-query"
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom"
import { useOdpClient } from "../../../../utils/odpClient"
import { S } from "./MetadataDetails.style"
import { S as Sa } from "../../Catalog.shared.style"
import AutocompleteDataSource from "../../components/AutocompleteDataSource"
import ChevronLeftIcon from "../../../../components/icons/ChevronLeftIcon"
import TabsSwitch from "./components/TabsSwitch"
import MiniMap from "./components/MiniMap"
import { Loader } from "../../../../components/Loader/Loader"
import { colors } from "../../../../styles/colors.style"
import { useDispatch } from "react-redux"
import { setExplorerLayersToSelectAC } from "../../../../utils/redux/actions/catalogActions"
import AddLayersToExplorerModal from "../CatalogResults/components/AddLayersModal/AddLayersToExplorerModal"

const sections = ["documentation", "metadata", "data access", "citation"] as const
export type MetadataSection = typeof sections[number]

const MetadataDetails = () => {
  const { id: metadataUuid } = useParams()
  const client = useOdpClient()
  const locationState = useLocation().state as { searchString: string }
  const navigate = useNavigate()
  const [selectedSection, setSelectedSection] = useState<MetadataSection>("documentation")
  const searchParam = locationState?.searchString
  const dispatch = useDispatch()

  const goBack = () => {
    navigate(-1)
  }

  const { data: metadata, isLoading } = useQuery<IDataProduct | undefined | null>(
    ["catalogGetDataProduct", metadataUuid],
    async () => {
      if (!metadataUuid) return
      return await client.getDataProductByUuid(metadataUuid)
    },
    { enabled: !!metadataUuid }
  )

  if (!metadataUuid) return <Navigate to="/catalog" />
  const dataProduct = metadata?.dataProduct
  if (!isLoading && !dataProduct?.uuid) return <Navigate to="/404" />
  const title = isLoading ? "Loading..." : dataProduct?.name

  const availableExplorerLayers = !!metadata?.layersMain?.length
  const showButtons = availableExplorerLayers

  const clickAddToExplorer = () => {
    if (availableExplorerLayers) dispatch(setExplorerLayersToSelectAC({ layers: metadata.layersMain }))
  }

  return (
    <Sa.Page>
      <S.Container>
        <Sa.SearchBar>
          <div onClick={goBack}>
            <ChevronLeftIcon />
          </div>
          <AutocompleteDataSource initialValue={searchParam || undefined} />
        </Sa.SearchBar>
        <h2>{title}</h2>
        {isLoading ? (
          <S.LoaderWrapper>
            <Loader color={colors.mainVioletO50} />
          </S.LoaderWrapper>
        ) : (
          dataProduct && (
            <>
              <S.TopContainter>
                <S.TagsWrapper>
                  {dataProduct?.tags.map((el, i) => (
                    <Sa.StyledTag key={"metaTag" + el + i}>{el}</Sa.StyledTag>
                  ))}
                </S.TagsWrapper>
                {showButtons && (
                  <S.ButtonContainter>
                    {availableExplorerLayers && (
                      <Sa.AddButtonGray onClick={clickAddToExplorer}>add to explorer</Sa.AddButtonGray>
                    )}
                  </S.ButtonContainter>
                )}
              </S.TopContainter>
              <S.DetailsNavigation>
                {sections.map(el => (
                  <S.SectionButton
                    key={el + "metamenuitem"}
                    selected={el === selectedSection}
                    onClick={() => setSelectedSection(el)}>
                    {el}
                  </S.SectionButton>
                ))}
              </S.DetailsNavigation>
              <S.Main>
                <div>
                  <TabsSwitch
                    dataProduct={dataProduct}
                    sectionName={selectedSection}
                    explorerLayers={metadata?.layersMain}
                  />
                </div>
                <S.MapSection>
                  <div>
                    {dataProduct.boundingBox && <MiniMap boundingBox={dataProduct.boundingBox} />}
                    {dataProduct.timespan && (
                      <span>
                        <strong>Years: </strong>
                        {dataProduct.timespan[0]} - {dataProduct.timespan[1]}
                      </span>
                    )}
                  </div>
                </S.MapSection>
              </S.Main>
            </>
          )
        )}
      </S.Container>
      <AddLayersToExplorerModal />
    </Sa.Page>
  )
}

export default MetadataDetails
