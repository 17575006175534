import * as React from "react"
import { Styled } from "./AccessDeniedPage.style"
import graphic from '../../assets/img/access-denied-graphic.png'
import logo from '../../assets/img/logo-with-text.svg'
import { Layout } from "../../components/OdpGlobalComponents/components/Layout/Layout"


const AccessDeniedPage = () => {
  return (
    <Layout>
      <Styled.Header>
        <a href={"https://oceandata.earth/"}>
          <img src={logo}/>
        </a>
      </Styled.Header>
      <Styled.Wrapper>
        <Styled.Content>
          <img src={graphic}/>
          <h1 >Thanks for joining us</h1>
          <p>
            The Ocean Data Platform is currently under private preview. Access will soon open up to a broader audience. 
            <br/>
            Join our community to be notified of our public launch and be given a special invitation to our Slack workspace.
          </p>
          <Styled.JoinButton href={"https://oceandata.earth/join-us"}>Join Our Community</Styled.JoinButton>
        </Styled.Content>
      </Styled.Wrapper>
    </Layout>
  )
}

export default AccessDeniedPage
