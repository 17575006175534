import styled from "styled-components"
import { colors } from "../../styles/colors.style"
import { StyledPage, SharedStyled } from "../../styles/shared.style"

const ViewWrapper = styled(StyledPage)`
  display: flex;
`

const LeftPanel = styled.div`
  padding: 35px;
  width: 330px;
  min-width: 300px;

  h2 {
    line-height: 38.5px;
    display: inline-block;
    margin: 0;
  }

  span {
    font-size: 10px;
    line-height: 9px;
    margin-left: 18px;
    ${SharedStyled.ComingSoonFont}
  }

  p {
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
  }
`

const Preview = styled.div`
  margin: 30px;
  margin-top: 60px;
  padding: 25px 120px 80px 120px;
  border-radius: 10px;
  background-color: ${colors.mainVioletO05};
  display: flex;
  flex-direction: column;
  align-items: center;

  h2:first-child {
    ${SharedStyled.ComingSoonFont}
    font-weight: 400;
    font-size: 26px;
    margin: 0;
  }

  h2 {
    text-align: center;
  }

  div {
    background-color: white;
    margin: 50px 0;
    position: relative;
    img {
      max-width: 730px;
      width: 100%;
      min-width: 300px;
      margin: 5px;
      vertical-align: top;
    }

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      background-color: ${colors.mainVioletO15};
      height: 130px;
      transform: skew(30deg) translate(-30px, 20px);
      left: 0;
      bottom: 0;
      filter: blur(15px);
      z-index: -1;
      transform-origin: bottom left;
    }
  }
`

const Content = styled.div`
  /* flex: 1; */
  display: flex;
  justify-content: center;
`

export const S = {
  ViewWrapper,
  Preview,
  LeftPanel,
  Content,
}
