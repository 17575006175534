import { createReducer } from "@reduxjs/toolkit"
import { AuthorizedGroups } from "../../useAuth"
import {
  resetUserStateAC,
  setAuthorizedGroupsAC,
  setUserInfoAC,
} from "../actions/userActions"

export interface IUserInfo {
  id: string
  name: string
  firstName: string
  lastName: string
  email: string
  groups: string[]
}

export interface IUserReduxState {
  authorizedGroups: Array<AuthorizedGroups>
  authenticated: boolean
  userInfo?: IUserInfo
}

const initialUserState: IUserReduxState = {
  authenticated: false,
  authorizedGroups: [],
}

const user = createReducer(
  () => initialUserState,
  builder => {
    builder
      .addCase(resetUserStateAC, state => {
        state = initialUserState
      })
      .addCase(setUserInfoAC, (state, action) => {
        state.authenticated = true
        state.userInfo = action.payload.userInfo
        state.authorizedGroups = action.payload.authorizedGroups
      })
      .addCase(setAuthorizedGroupsAC, (state, action) => {
        state.authorizedGroups = action.payload.authorizedGroups
      })
  }
)

export default user
