import { IDataProductMeta } from "odp-sdk/dist/types"
import React from "react"
import { S } from "./Tabs.shared.style"

type Props = {
  dataProduct: IDataProductMeta
}

const DocumentationTab = ({ dataProduct }: Props) => {
  return (
    <S.Tab>
      {dataProduct.databaseDescription && (
        <>
          <h4>Overview</h4>
          <p>{dataProduct.databaseDescription}</p>
        </>
      )}
    </S.Tab>
  )
}

export default DocumentationTab
