import * as React from "react"
import { Loader } from "../../components/Loader/Loader"
import { Layout } from "../../components/OdpGlobalComponents/components/Layout/Layout"
import { Styled } from "./WelcomePage.style"

const WelcomePage: React.FC = () => {
  return (
    <Layout>
      <Styled.WelcomePage>
        <Loader/>
        <h2>Preparing to dive...</h2>
      </Styled.WelcomePage>
    </Layout>
  )
}

export default WelcomePage
