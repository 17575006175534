import { css } from "styled-components";
import { typeScale } from "../../confTypeScale";
import { mediaMin } from "../../mixMediaQuery";

export const GlobalSmall = css`
  small {
    ${typeScale.PRESET_0}

    ${mediaMin.WIDTH_2} {
      ${typeScale.PRESET_1}
    }
  }
`;
