import mapboxgl, { LngLat } from "mapbox-gl"
import { ICastRow } from "odp-sdk"

export enum dataTypeEnum {
  aggregatedPoint = "aggregatedPoint",
  single = "single",
  simpleList = "simpleList",
}

export interface IInfoPanelData {
  id?: number | string | null
  name?: string
  simpleList?: { [s: string]: string | number }
  dataType: dataTypeEnum
  rowNumber?: number | null
  externalId?: string | null
  location: { lat: number; lng: number }
  metadata?: Array<{ label: string; value: string }>
  value?: {
    count?: string | null
    country?: string | null
    depthProfileTest?: string | null
    equipment?: string | null
    Institute?: string | null
    Nitrate_first?: string | number | null
    nitrate?: string | null
    Oxygen_first?: string | number | null
    oxygen?: string | null
    pH_first?: string | number | null
    ph?: string | null
    Salinity_first?: string | number | null
    salinity?: string | null
    Temperature_first?: string | number | null
    temperature?: string | null
    WOD_cruise_identifier?: string | null
    z_first?: string | number | null
    z_last?: string | number | null
    valueRange?: {
      name: string
      unit: string
      minValue: string
      maxValue: string
      timestampMin: string
      timestampMax: string
    }
  }
  time?: string | null
}

function parseInfoPanelData(
  feature:
    | mapboxgl.MapboxGeoJSONFeature
    | Record<string, string | { [s: string]: string | number }>,
  variable: string,
  dataType: dataTypeEnum,
  eventLocation: LngLat,
  unit?: string,
  metadata?: Array<{ label: string; value: string }>
): IInfoPanelData {
  // TODO: Note: Move this logic to the SDK
  switch (dataType) {
    case dataTypeEnum.aggregatedPoint:
      return parseAggregatedPoint(
        feature as mapboxgl.MapboxGeoJSONFeature,
        variable,
        unit,
        eventLocation,
        metadata
      )
    case dataTypeEnum.single:
      return parseSingle(feature as Record<string, string>)
    case dataTypeEnum.simpleList:
      return parseSimpleList(
        feature as Record<string, string | { [s: string]: string | number }>,
        eventLocation
      )
  }
}

const parseAggregatedPoint = (
  feature: mapboxgl.MapboxGeoJSONFeature,
  variable: string,
  unit = "",
  eventLocation: LngLat,
  metadata?: Array<{ label: string; value: string }>
): IInfoPanelData => {
  const aggregatedData: IInfoPanelData = {
    dataType: dataTypeEnum.aggregatedPoint,
    location:
      feature.geometry.type == "Point"
        ? {
            lng: feature.geometry.coordinates[0],
            lat: feature.geometry.coordinates[1],
          }
        : eventLocation,
    metadata: metadata,
  }
  if (!feature.properties) {
    return aggregatedData
  } else {
    const keyMinValue = Object.keys(feature.properties).find(
      el =>
        el.includes("_min") && !el.includes("timestamp_") && !el.includes("z_")
    )
    const keyMaxValue = Object.keys(feature.properties).find(
      el =>
        el.includes("_max") && !el.includes("timestamp_") && !el.includes("z_")
    )

    return {
      ...aggregatedData,
      value: {
        z_first: feature.properties.z_min as string,
        z_last: feature.properties.z_max as string,
        valueRange: {
          name: variable,
          unit: unit,
          minValue:
            feature.properties && keyMinValue
              ? feature.properties[keyMinValue]
              : "",
          maxValue:
            feature.properties && keyMaxValue
              ? feature.properties[keyMaxValue]
              : "",
          timestampMin: feature.properties.timestamp_min as string,
          timestampMax: feature.properties.timestamp_max as string,
        },
      },
    }
  }
}

const parseSimpleList = (
  feature: Record<string, string | { [s: string]: string | number }>,
  eventLocation: LngLat
): IInfoPanelData => {
  return {
    dataType: dataTypeEnum.simpleList,
    name: feature.source as string,
    simpleList: feature.properties as { [s: string]: string | number },
    location: eventLocation,
  }
}

const parseSingle = (data: Record<string, string>): IInfoPanelData => {
  return {
    id: JSON.parse(data.id) as number,
    dataType: dataTypeEnum.single,
    rowNumber: JSON.parse(data.rowNumber) as number,
    externalId: data.externalId as string,
    location: JSON.parse(data.location) as { lat: number; lng: number },
    value: JSON.parse(data.value) as IInfoPanelData["value"],
    time: data.time as string,
  }
}

export const SET_INFO_PANEL_DATA = "SET_INFO_PANEL_DATA"
export function setInfoPanelData(
  feature: mapboxgl.MapboxGeoJSONFeature | Record<string, string>,
  variable: string,
  dataType: dataTypeEnum,
  eventLocation: LngLat,
  unit?: string,
  metadata?: Array<{ label: string; value: string }>
) {
  return {
    type: SET_INFO_PANEL_DATA,
    parsedData: parseInfoPanelData(
      feature,
      variable,
      dataType,
      eventLocation,
      unit,
      metadata
    ),
  }
}

export const SET_INFO_PANEL_DATA_UNCLUSTERED = "SET_INFO_PANEL_DATA_UNCLUSTERED"
export function setInfoPanelDataUnclustered(data) {
  return {
    type: SET_INFO_PANEL_DATA_UNCLUSTERED,
    data: {
      externalId: data.externalId,
      location: JSON.parse(data.location),
      rowNumber: data.rowNumber,
      value: JSON.parse(data.value),
    },
  }
}
