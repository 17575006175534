import { css } from "styled-components";
import { colors } from "../../../../styles/colors.style";
import { token } from "../../tokens";

export const GlobalLink = css`
  a {
    color: ${colors.surfieGreen};
    transition: color
      ${token.transition.duration.SPEED_3 +
        " " +
        token.transition.timing.EFFECT_3};

    &:hover,
    &:focus {
      color: ${colors.smoky};
      text-decoration: none;
    }
  }
`;
