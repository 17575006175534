import { createAction } from "@reduxjs/toolkit"
import { IDataLayerMain, IDataProductResult } from "odp-sdk/dist/types"

export enum CatalogActionNames {
  GET_CATALOG_RESULTS = "GET_CATALOG_RESULTS",
  TOGGLE_FILTER_PRIVATE_DATASETS = "TOGGLE_FILTER_PRIVATE_DATASETS",
  TOGGLE_FILTER_PUBLIC_DATASETS = "TOGGLE_FILTER_PUBLIC_DATASETS",
  TOGGLE_FILTER_AVAILABLE_IN_EXPLORER = "TOGGLE_FILTER_AVAILABLE_IN_EXPLORER",
  TOGGLE_FILTER_AVAILABLE_IN_CONNECTOR = "TOGGLE_FILTER_AVAILABLE_IN_CONNECTOR",
  TOGGLE_FILTER_GRIDDED = "TOGGLE_FILTER_GRIDDED",
  SET_FILTER_START_YEAR = "SET_FILTER_START_YEAR",
  SET_FILTER_END_YEAR = "SET_FILTER_END_YEAR",
  SET_EXPLORER_LAYERS_TO_SELECT = "SET_EXPLORER_LAYERS_TO_SELECT",
  CLEAR_FILTERS = "CLEAR_FILTERS",
}

export const toggleFilterPrivateDatasetsAC = createAction(
  CatalogActionNames.TOGGLE_FILTER_PRIVATE_DATASETS
)
export const toggleFilterPublicDatasetsAC = createAction(
  CatalogActionNames.TOGGLE_FILTER_PUBLIC_DATASETS
)
export const toggleFilterExplorerAvailableDatasetsAC = createAction(
  CatalogActionNames.TOGGLE_FILTER_AVAILABLE_IN_EXPLORER
)
export const toggleFilterConnectorAvailableDatasetsAC = createAction(
  CatalogActionNames.TOGGLE_FILTER_AVAILABLE_IN_CONNECTOR
)
export const toggleFilterGriddedAC = createAction(
  CatalogActionNames.TOGGLE_FILTER_GRIDDED
)
export const getCatalogResultsAC = createAction<{
  results: IDataProductResult[]
}>(CatalogActionNames.GET_CATALOG_RESULTS)
export const setFilterStartYearAC = createAction<{
  startYear: number | undefined
}>(CatalogActionNames.SET_FILTER_START_YEAR)
export const setFilterEndYearAC = createAction<{ endYear: number | undefined }>(
  CatalogActionNames.SET_FILTER_END_YEAR
)
export const setExplorerLayersToSelectAC = createAction<{
  layers: IDataLayerMain[]
}>(CatalogActionNames.SET_EXPLORER_LAYERS_TO_SELECT)
export const clearFiltersAC = createAction(CatalogActionNames.CLEAR_FILTERS)
