import styled from "styled-components"
import { colors } from "../../../../../styles/colors.style"

const StyledTile = styled.span`
  border-radius: 8px;
  background-color: ${colors.mainVioletO05};
  width: 165px;
  height: 165px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  cursor: pointer;
  padding: 30px;
  text-transform: uppercase;
  margin: 0 12px 24px 12px;
  gap: 10px;
  transition: background-color 200ms ease-in-out;

  &:hover {
    background-color: ${colors.mainVioletO03};
  }

  span {
    text-align: center;
    font-size: 14px;
    line-height: 18px;
  }
`

export const S = {
  StyledTile,
}
