import styled from "styled-components"
import { colors } from "../../../../../styles/colors.style"
import { S as CatalogStyles } from "../../../Catalog.shared.style"

const StyledTag = styled(CatalogStyles.StyledTag)`
  margin: 0 4px;
  cursor: pointer;

  &:hover {
    background-color: ${colors.mainVioletO03};
  }
  transition: background-color 200ms ease-in-out;
`

export const S = {
  StyledTag,
}
