import { css } from "styled-components";
import { colors } from "../../../../styles/colors.style";
import { typeScale } from "../../confTypeScale";
import { token } from "../../tokens";

export const GlobalBody = css`
  body {
    padding: 0;
    margin: 0;
    color: ${colors.mainViolet};
    font-family: ${token.typography.family.FAMILY_1};

    ${typeScale.PRESET_2};

    &::backdrop {
      background-color: white;
    }
  }
`;
