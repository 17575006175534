import React from "react"
import SearchField from "./SearchField"
import DropDown from "./DropDown"
import styled from "styled-components"
import { motion } from "framer-motion"
import { mediaMax } from "../OdpGlobalComponents/mixMediaQuery"

export const HeaderBody = styled(motion.header)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;

  ${mediaMax.WIDTH_3} {
    & > div > strong {
      font-size: 18px !important;
    }
  }
`

interface Props {
  updateQuery: (value: string) => void
  updateCategory: (value: string) => void
  categories: Array<string>
  selectedCategory: string
  searchQuery: string
}

const FilterHeader = ({
  updateQuery,
  searchQuery,
  categories,
  selectedCategory,
  updateCategory,
}: Props) => {
  return (
    <HeaderBody>
      <SearchField
        onChange={value => updateQuery(value)}
        placeholder={`Search ${selectedCategory.toLowerCase()} `}
        value={searchQuery}
        handleClear={() => updateQuery("")}
      />
      <DropDown
        handleSelection={updateCategory}
        categories={categories}
        selectedCategory={selectedCategory}
      />
    </HeaderBody>
  )
}

export default FilterHeader
