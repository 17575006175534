import styled from "styled-components"
import { mediaMin } from "../../../../../components/OdpGlobalComponents/mixMediaQuery"
import { token } from "../../../../../components/OdpGlobalComponents/tokens"

const Results = styled.div`
  padding: ${token.space.SPACING_4} 0;

  ${mediaMin.WIDTH_4} {
    padding: ${token.space.SPACING_4} ${token.space.SPACING_7};
  }
  flex: 0.7;
`

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
`

export const S = {
  Results,
  LoaderWrapper
}
