export const colors = {
  white: "rgba(255, 255, 255, 1)",

  mainViolet: "rgba(32, 10, 58, 1)",
  mainVioletO03: "rgb(248, 248, 249)", //equivalent of  rgba(32, 10, 58, 0.03)
  mainVioletO05: "rgb(244, 243, 245)", //equivalent of rgba(32, 10, 58, 0.05)
  mainVioletO10: "rgb(233, 231, 235)", //equivalent of rgba(32, 10, 58, 0.1)
  mainVioletO15: "rgb(222, 218, 225)", //equivalent of rgba(32, 10, 58, 0.15)
  mainVioletO20: "rgb(210, 206, 216)", //equivalent of rgba(32, 10, 58, 0.20)
  mainVioletO30: "rgb(188, 182, 196)", //equivalent of rgba(32, 10, 58, 0.30)
  mainVioletO50: "rgb(143, 132, 156)", //equivalent of rgba(32, 10, 58, 0.50)
  mainVioletO60: "rgb(121, 108, 137)", //equivalent of rgba(32, 10, 58, 0.60)
  mainVioletO70: "rgb(99, 84, 117)", //equivalent of rgba(32, 10, 58, 0.70)
  mainVioletO80: "rgb(77, 59, 97)", //equivalent of rgba(32, 10, 58, 0.80)
  mainVioletO90: "rgb(54, 35, 78)", //equivalent of rgba(32, 10, 58, 0.90)

  seaGreen: "rgba(2, 255, 209, 1)",
  seaGreenO30: "rgb(175, 255, 241)", //equivalent of "rgba(2, 255, 209, 0.30)",

  surfieGreen: "rgba(17, 132, 133, 1)",
  smoky: "rgba(99, 83, 117, 1)",
  graySuit: "rgba(199, 193, 205, 1)",
  mediumPurple: "rgba(148, 98, 237, 1)",

  errorSignifierColor: "rgba(227, 95, 56,1)",
}