import styled from "styled-components"
import { mediaMin } from "../../../../components/OdpGlobalComponents/mixMediaQuery"
import { token } from "../../../../components/OdpGlobalComponents/tokens"
import { colors } from "../../../../styles/colors.style"

const ViewWrapper = styled.div`
  position: absolute;
  right: 0;
  width: calc(100vw - ${token.space.SPACING_14});
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  ${mediaMin.WIDTH_2} {
    width: calc(100vw - ${token.space.SPACING_20});
  }
  ${mediaMin.WIDTH_3} {
    width: calc(100vw - 5.25rem);
  }
`

const DataCatalogWrapper = styled(ViewWrapper)`
  color: ${colors.mainViolet};
  flex-direction: column;
  margin-top: -50px;

  h2 {
    font-family: ${token.typography.family.FAMILY_2};
    font-size: 28px;
    line-height: 38px;
    margin-bottom: 24px;
    font-weight: 500;
  }
`

const SearchbarWrapper = styled.div`
  width: 500px;
`

const TagsWrapper = styled.div`
  display: flex;
  margin-top: 18px;
`

const TileWrapper = styled.div`
  display: flex;
  margin-top: 18px;
  justify-content: center;
  flex-wrap: wrap;
`

const SearchSegment = styled.div`
  margin-bottom: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const S = {
  SearchSegment,
  TagsWrapper,
  TileWrapper,
  DataCatalogWrapper,
  SearchbarWrapper,
}
