import React from "react"
import { suggestedDataSets } from "../../catalog.helpers"
import AutocompleteDataSource from "../../components/AutocompleteDataSource"
import SearchTag from "./components/SearchTag"
import SearchTile from "./components/SearchTile"
import { S } from "./CatalogSearch.style"

const DataCatalog = () => {
  const searchableTags = ["world", "salinity", "temperature"]

  return (
    <S.DataCatalogWrapper>
      <S.SearchSegment>
        <h2>Search Data Catalog</h2>
        <S.SearchbarWrapper>
          <AutocompleteDataSource />
        </S.SearchbarWrapper>
        <S.TagsWrapper>
          {searchableTags.map((tag, i) => (
            <SearchTag tagName={tag} key={"searchtag" + tag + i} />
          ))}
        </S.TagsWrapper>
      </S.SearchSegment>

      <h2>Explore Data</h2>
      <S.TileWrapper>
        {Object.entries(suggestedDataSets).map(([name, id]) => (
          <SearchTile text={name} id={id} key={"searchtile" + id} />
        ))}
      </S.TileWrapper>
    </S.DataCatalogWrapper>
  )
}

export default DataCatalog
