import * as React from "react"
import { S } from "./ConnectorVerticalTabPanel.style"

interface IConnectorVerticalTabPanel {
  handleContentViewChange: (index: number) => void
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  }
}

export default function ConnectorVerticalTabPanel(
  props: IConnectorVerticalTabPanel
) {
  const [value, setValue] = React.useState(0)

  const handleTabClick = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <S.ConnectorTabs
      orientation="vertical"
      value={value} // Necessary so react can set the style of the active button correctly
      onChange={(ev, val) => {
        handleTabClick(ev, val)
        props.handleContentViewChange(val)
      }}
      aria-label="Vertical tabs ">
      <S.ConnectorTab label="OCEAN DATA CONNECTOR" {...a11yProps(0)} />
      <S.ConnectorTab label="SDKS" {...a11yProps(1)} />
      <S.ConnectorTab label="PACKAGES" {...a11yProps(2)} />
      <S.ConnectorTab label="API" {...a11yProps(3)} disabled={true} />
    </S.ConnectorTabs>
  )
}
