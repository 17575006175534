import React from "react"
import { SharedStyled } from "../../../styles/shared.style"
import { S } from "./ConnectorPackageList.style"
import daskBanner from "../../../assets/img/connector/connector-dask.svg"
import xarrayBanner from "../../../assets/img/connector/connector-xarray.svg"
import jupyterBanner from "../../../assets/img/connector/connector-jupyter.svg"
import pandasBanner from "../../../assets/img/connector/connector-pandas.svg"
import geoPandasBanner from "../../../assets/img/connector/connector-geopandas.svg"
import matplotlibBanner from "../../../assets/img/connector/connector-matplotlib.svg"
import cartopyBanner from "../../../assets/img/connector/connector-cartopy.svg"

type TConnectorPackage = {
  banner: string
  headline: string
  description: string
  website: string
  gitHub: string
}

const connectorPackages: TConnectorPackage[] = [
  {
    banner: daskBanner,
    headline: "DASK",
    description:
      "Dask is an open source library for parallel computing. It can easily be used with Pandas, Numpy and scikit-learn to speed up data through parallelization. Dask data structures can represent large data structure without loading them into memory allowing for scalability.",
    website: "https://dask.org/",
    gitHub: "https://github.com/dask/dask",
  },
  {
    banner: xarrayBanner,
    headline: "XARRAY",
    description:
      "Xarray is an open source Python package to work with multi-dimensional arrays of data. It is tailored to working with netCDF files and is widely used in earth sciences. It provides many functions for advanced analytics and visualizations. It can be integrated with Dask for parallel computing.",
    website: "http://xarray.pydata.org/en/stable/",
    gitHub: "https://github.com/pydata/xarray",
  },
  {
    banner: jupyterBanner,
    headline: "JUPYTER",
    description:
      "Project Jupyter is a community dedicated to devlop open-source software and support open standards. Through Jupyter users can interact with the data in the Ocean Data Platform and our resources.",
    website: "https://jupyter.org/",
    gitHub: "https://github.com/jupyter",
  },
  {
    banner: pandasBanner,
    headline: "PANDAS",
    description:
      "Pandas provides data in a dataframe format that makes it easy for users to manipulate data. The noteworthy highlights are: tools for reading and writing data, reshaping dataset, group bys, merging and joining of datasets, and time series functionality. Our datapulls return either pandas or geopandas dataframes that are easy to work with and provide a single data format.",
    website: "https://pandas.pydata.org/",
    gitHub: "https://github.com/pandas-dev/pandas/",
  },
  {
    banner: geoPandasBanner,
    headline: "GEOPANDAS",
    description:
      "Geopandas allows users to easily work with geospatial data by extending the datatypes found in pandas to perform spatial operations. Geometric operations are performed by shapely. Data in our example notebooks are being pulled using a geopandas to sql connector.",
    website: "https://geopandas.org/index.html",
    gitHub: "https://github.com/geopandas/geopandas",
  },
  {
    banner: matplotlibBanner,
    headline: "MATPLOTLIB",
    description:
      "Matplotlib is an open-source python library for creating static, animated and interactive visualizations. It can integrate easily with Cartopy and used widely in our example notebooks.",
    website: "https://matplotlib.org/",
    gitHub: "https://github.com/matplotlib/matplotlib",
  },
  {
    banner: cartopyBanner,
    headline: "CARTOPY",
    description:
      "Cartopy is a Python package for geospatial data processing, producing maps and other geospatial data analyses. It allows users to switch between different projections and can be easily integrated with Matplotlib. Many of the maps in our example notebooks are created using Cartopy. Previously knows as Basemap.",
    website: "https://scitools.org.uk/cartopy/docs/latest/",
    gitHub: "https://github.com/SciTools/cartopy",
  },
]

function ConnectorPackageList() {
  return (
    <S.ConnectorPackagesContentWrapper>
      <SharedStyled.h2_Odp>Packages</SharedStyled.h2_Odp>
      <p>
        The Ocean Data Platform environment is made of up of many different
        open-source software packages. Each of these packages has its own
        repository and documentation.
      </p>
      <S.ConnectorPackagesWrapper>
        {connectorPackages.map((connectorPackage, index) => {
          return (
            <ConnectorPackage
              key={`connector-package-${index}-${connectorPackage.headline}`}
              {...connectorPackage}
            />
          )
        })}
      </S.ConnectorPackagesWrapper>
    </S.ConnectorPackagesContentWrapper>
  )
}

function ConnectorPackage({
  banner,
  headline,
  description,
  website,
  gitHub,
}: TConnectorPackage) {
  return (
    <S.ConnectorPackageWrapper>
      <img src={banner} />
      <S.ConnectorPackageHeadline>{headline}</S.ConnectorPackageHeadline>
      <S.ConnectorPackageDescription>
        {description}
      </S.ConnectorPackageDescription>
      <S.ConnectorPackageLink href={website} target="_blank">
        Website
      </S.ConnectorPackageLink>
      |
      <S.ConnectorPackageLink href={gitHub} target="_blank">
        GitHub
      </S.ConnectorPackageLink>
    </S.ConnectorPackageWrapper>
  )
}

export default ConnectorPackageList
