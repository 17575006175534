import styled from "styled-components"
import {
  Autocomplete as MuiAutocomplete,
  Popper as MUIPopper,
} from "@mui/material"
import { colors } from "../../../styles/colors.style"
import { token } from "../../../components/OdpGlobalComponents/tokens"

const iconsSpacing = "8px"

const AutoCompleteWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`

const ClearIconWrapper = styled.div`
  cursor: pointer;
  visibility: visible;
  display: flex;
  align-items: center;
  border-left: 2px solid ${colors.mainVioletO30};
  padding-left: ${iconsSpacing};
  svg {
    color: ${colors.mainVioletO30};
    transform: scale(0.9);
    &:hover {
      color: ${colors.mainVioletO50};
      background-color: none;
    }
  }
`

const SearchIconWrapper = styled.div`
  display: flex;
  padding-right: ${iconsSpacing};
  > svg {
    transform: scale(0.9);
  }
`

const Autocomplete = styled(MuiAutocomplete)`
  flex-basis: 100%;
  margin-right: ${token.space.SPACING_2};

  .MuiFormControl-root {
    .MuiOutlinedInput-root {
      border-radius: 10px;

      .MuiOutlinedInput-notchedOutline {
        border-color: ${colors.mainVioletO30};
      }

      &:hover {
        .MuiOutlinedInput-notchedOutline {
          border-color: ${colors.mainViolet};
        }
      }
    }

    .MuiAutocomplete-endAdornment {
      top: initial;
      .MuiAutocomplete-clearIndicator {
        background: none;
        border-radius: 0;
      }
    }
  }
` as typeof MuiAutocomplete

const Popper = styled(MUIPopper)`
  .MuiAutocomplete-listbox {
    .MuiAutocomplete-option.Mui-focused {
      background-color: ${colors.mainVioletO03};
    }
    .MuiAutocomplete-option:hover {
      background-color: ${colors.mainVioletO10};
    }
  }
` as typeof MUIPopper

export const S = {
  Popper,
  ClearIconWrapper,
  SearchIconWrapper,
  Autocomplete,
  AutoCompleteWrapper,
}
