import React from "react"
import { SharedStyled } from "../../../styles/shared.style"
import { S } from "./ConnectorSdkList.style"
import jsSdkIcon from "../../../assets/img/connector/connector-sdk-js.svg"
import pythonSdkIcon from "../../../assets/img/connector/connector-sdk-python.svg"

type TConnectorSdk = {
  icon: string
  headline: string
  descriptionParagraphOne: string
  descriptionParagraphTwo: string
  gitHub: string
  comingSoon: boolean
}

const connectorSdks: TConnectorSdk[] = [
  {
    icon: jsSdkIcon,
    headline: "JAVASCRIPT",
    descriptionParagraphOne:
      "The JavaScript SDK provides functionality to pull data into our Ocean Data Explorer.",
    descriptionParagraphTwo:
      "Users can use our SDK to build their own front-end or to add desired functionality to the Ocean Data Explorer.",
    gitHub: "https://github.com/C4IROcean/odp-sdk-js",
    comingSoon: false,
  },
  {
    icon: pythonSdkIcon,
    headline: "PYTHON",
    descriptionParagraphOne:
      "The Python SDK provides functionality to pull all available data from the Ocean Data Platform and can be run using the Ocean Data Connector, a JupyterHub environment.",
    descriptionParagraphTwo:
      "Users can run for fast and efficient queries  allowing for joining of data one environment from a variety of datasources.",
    gitHub: "",
    comingSoon: true,
  },
]

function ConnectorSdkList() {
  return (
    <S.ConnectorSdksContentWrapper>
      <SharedStyled.h2_Odp>Software Development Kits</SharedStyled.h2_Odp>
      <S.ConnectorSdksWrapper>
        {connectorSdks.map((sdk, index) => {
          return (
            <ConnectorSdkEntry
              key={`connector-package-${index}-${sdk.headline}`}
              {...sdk}
            />
          )
        })}
      </S.ConnectorSdksWrapper>
    </S.ConnectorSdksContentWrapper>
  )
}

function ConnectorSdkEntry({
  icon,
  headline,
  descriptionParagraphOne,
  descriptionParagraphTwo,
  gitHub,
  comingSoon,
}: TConnectorSdk) {
  return (
    <S.ConnectorSdkWrapper>
      <img src={icon} />
      <div>
        <S.ConnectorSdkHeadline>{headline}</S.ConnectorSdkHeadline>
        <S.ConnectorSdkDescription>
          {descriptionParagraphOne}
        </S.ConnectorSdkDescription>
        <S.ConnectorSdkDescription>
          {descriptionParagraphTwo}
        </S.ConnectorSdkDescription>
        <SharedStyled.OdpLinkButton
          href={gitHub}
          target="_blank"
          comingSoon={comingSoon}>
          GITHUB
        </SharedStyled.OdpLinkButton>
        {comingSoon && (
          <SharedStyled.OdpButtonComingSoon>
            Coming soon
          </SharedStyled.OdpButtonComingSoon>
        )}
      </div>
    </S.ConnectorSdkWrapper>
  )
}

export default ConnectorSdkList
