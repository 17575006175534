import { motion } from "framer-motion"
import React from "react"
import styled, { css } from "styled-components"
import { colors } from "../../styles/colors.style"
import { AuthorizedGroups } from "../../utils/useAuth"
import { token } from "../OdpGlobalComponents/tokens"
import { OverlayTypes } from "./types"

const ItemCardContainer = styled(motion.a)<{ userHasAccess: boolean }>`
  background-color: ${colors.white};
  padding: 30px 20px;
  border-radius: 10px;
  display: flex;
  border: ${token.border.width.WIDTH_1} solid ${colors.mainVioletO10};
  text-decoration: none;
  cursor: pointer;
  color: inherit;
  ${props =>
    !props.userHasAccess &&
    css`
      cursor: initial;
      opacity: 0.4;
      filter: grayscale(100%); ;
    `}

  &:hover {
    color: inherit;
  }

  &:visited {
    color: inherit;
  }
`

const ItemCardContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const ItemTitle = styled.strong`
  font-size: 14px;
  margin-top: 18px;
  text-transform: uppercase;
`

const ItemDescription = styled.p`
  font-size: 14px;
  margin-top: 0;
  flex-grow: 1;
`

interface ImageProps {
  variant: OverlayTypes
}

const ItemCardImage = styled.img<ImageProps>`
  border-radius: 10px;
  width: ${props => (props.variant === "connect" ? "60%" : "100%")};
  max-height: 163px;
  object-fit: ${props => (props.variant === "connect" ? "initial" : "cover")};
`

const ImageBanner = styled.p`
  font-size: 14px;
  line-height: 100%;
  color: ${colors.white};
  background: ${colors.surfieGreen};
  margin: 0;
  padding: 7px;
  position: absolute;
  bottom: 0;
  left: 10px;
`

const ItemIconContainer = styled.div`
  bottom: 10px;
  right: 10px;
  position: absolute;
`

export interface ListCardItemProps {
  imgSrc: string
  title: string
  description: string
  authorizationGroups: AuthorizedGroups[]
  category?: string
  privateProject?: boolean
  url?: string
  onClick?: () => void
  variant?: OverlayTypes
  iconSrc?: string
}

const ListCardItem = ({
  imgSrc,
  description,
  category,
  privateProject,
  title,
  url,
  onClick,
  variant,
  iconSrc,
}: ListCardItemProps) => {
  const formattedURL = url && url.includes("https://") ? url : `https://${url}`

  return (
    <ItemCardContainer
      whileHover={
        !privateProject
          ? {
              scale: 1.06,
              boxShadow: `0 0 7px 0 ${colors.mainVioletO20}`,
              transition: { type: "spring", stiffness: 200 },
            }
          : {}
      }
      userHasAccess={!privateProject}
      onClick={onClick}
      href={url && formattedURL}
      target={"_blank"}
      rel={"noreferrer"}>
      <ItemCardContent>
        <div
          style={{
            width: "100%",
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <ItemCardImage
            src={imgSrc}
            alt={"cover photo"}
            variant={variant ?? ""}
          />
          {variant === "projects" && (
            <React.Fragment>
              <ImageBanner>{category}</ImageBanner>
              <ItemIconContainer>
                <img src={iconSrc} alt="company logo" />
              </ItemIconContainer>
            </React.Fragment>
          )}
        </div>
        <ItemTitle>{title}</ItemTitle>
        <ItemDescription>
          {variant === "projects" && <strong>Author: </strong>}
          {description}
        </ItemDescription>
      </ItemCardContent>
    </ItemCardContainer>
  )
}

export default ListCardItem
