import { FormControlLabel as MUIFormControlLabel, TextField } from "@mui/material"
import styled from "styled-components"
import { mediaMin } from "../../../../../components/OdpGlobalComponents/mixMediaQuery"
import { token } from "../../../../../components/OdpGlobalComponents/tokens"
import { colors } from "../../../../../styles/colors.style"

const FilterPanel = styled.div`
  display: flex;
  color: ${colors.smoky};
  font-size: ${token.typography.size.SIZE_1};
  align-self: stretch;
  border-bottom: 1px solid ${colors.mainVioletO10};
  position: relative;
  > div:first-child {
    border-right: 1px solid ${colors.mainVioletO10};
  }
  > div:nth-child(2) {
    padding-left: ${token.space.SPACING_10};
  }

  ${mediaMin.WIDTH_4} {
    border-bottom: none;
    flex: 0.3;
    flex-direction: column;
    border-right: 1px solid ${colors.mainVioletO10};

    > div:first-child {
      border-right: none;
      border-bottom: 1px solid ${colors.mainVioletO10};
    }

    > div:nth-child(2) {
      padding-left: 0;
    }
  }
`

const FilterPanelSegment = styled.div`
  padding: ${token.space.SPACING_4} ${token.space.SPACING_4} 0
    ${token.space.SPACING_4};
  flex: 1;

  ${mediaMin.WIDTH_4} {
    padding: ${token.space.SPACING_8} ${token.space.SPACING_4} 0 0;
    flex: 0;
  }

  > span {
    text-transform: uppercase;
    margin-bottom: 10px;
    display: block;
    letter-spacing: 1px;
  }
`

const FilterPanelSegmentContent = styled.div`
  margin-bottom: ${token.space.SPACING_8};
`

const FilterDate = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 6px;

  .MuiFormControl-root {
    max-width: 200px;
  }
`

const MaterialYearInput = styled(TextField)`
  .MuiFormControl-root {
    max-width: 200px;
  }
  & .MuiOutlinedInput-root {
    color: ${colors.mainViolet};

    & .MuiOutlinedInput-notchedOutline {
      border-color: ${colors.mainVioletO10};
    }
    &:hover .MuiOutlinedInput-notchedOutline {
      border-color: ${colors.mainViolet};
    }

    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: ${colors.mainViolet};
    }
  }
`

const PanelExpandBtn = styled.button`
  border: none;
  width: 30px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  ${mediaMin.WIDTH_4} {
    display: none;
  }
`

const FormControlLabel = styled(MUIFormControlLabel)`
  .MuiCheckbox-root {
    color: ${colors.mainVioletO10};
    &.Mui-checked {
      color: ${colors.smoky};
    }
    &:hover { 
      background: ${colors.mainVioletO03};
    }
  }

  .MuiFormControlLabel-label {
    font-size: ${token.typography.size.SIZE_1};
  }
`

export const S = {
  FilterPanel,
  FilterPanelSegment,
  FilterDate,
  PanelExpandBtn,
  FilterPanelSegmentContent,
  MaterialYearInput,
  FormControlLabel
}
