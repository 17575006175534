import React from "react"
import { S } from "./ConnectorOdc.style"
import jellyfishIcon from "../../../assets/img/connector/connector-odc-jellyfish.svg"
import envOptionsImg from "../../../assets/img/connector/connector-odc-env-options.png"
import analysisExampleImg from "../../../assets/img/connector/connector-odc-analysis-example.png"
import { SharedStyled } from "../../../styles/shared.style"
import { AuthorizedGroups, useAuth } from "../../../utils/useAuth"

const ConnectorOdc = () => {
  const { hasUserAuthorization } = useAuth()

  return (
    <S.ConnectorOdcContentWrapper>
      <img src={jellyfishIcon} />
      <S.ConnectorOdcHeadline>
        <b>Ocean Data</b> Connector
      </S.ConnectorOdcHeadline>
      <S.ConnectorOdcDescription>
        The Ocean Data Connector is a tool that allows you to query, fuse and analyse data all in the cloud, eliminating
        the obstacles of storage space and computing power. Powered by Jupyter and Dask.
      </S.ConnectorOdcDescription>
      <SharedStyled.OdpLinkButton
        href="https://workspace.hubocean.earth/"
        target="_blank"
        comingSoon={!hasUserAuthorization([AuthorizedGroups.ODC_ACCESS_DASKHUB_USERS])}>
        LAUNCH THE OCEAN DATA CONNECTOR
      </SharedStyled.OdpLinkButton>
      {!hasUserAuthorization([AuthorizedGroups.ODC_ACCESS_DASKHUB_USERS]) && (
        <SharedStyled.OdpButtonComingSoon>Coming soon</SharedStyled.OdpButtonComingSoon>
      )}
      <S.ConnectorOdcExamplesWrapper>
        <S.ConnectorOdcExampleImgs src={envOptionsImg} />
        <S.ConnectorOdcExampleImgs src={analysisExampleImg} />
      </S.ConnectorOdcExamplesWrapper>
    </S.ConnectorOdcContentWrapper>
  )
}

export default ConnectorOdc
