import { useNavigate } from "react-router-dom"

export const useCatalogSearch = () => {
  const navigate = useNavigate()

  const searchFunction = (searchString: string) => {
    navigate({
      pathname: "/catalog/search",
      search: `?q=${searchString}`,
    })
  }

  return { searchFunction }
}

export const suggestedDataSets: Record<string, string> = {
  'GEBCO gridded bathymetry data': '0baba11b-422b-4dd6-bc29-2a3a150dbbc9',
  'Ocean Biodiversity Information System': '0dbfd46c-fc24-46aa-8c3a-0916b7bc363e',
  'World Ocean Database Measurements': 'ace482c9-8ab0-4b36-88ad-71593a26cf21',
  'Emodnet HA offshore installations': '98df701e-ff85-43d9-95aa-aa410d90368a',
  'World Ocean Atlas 2018 Temperature': '2053bd66-3fad-4601-85b9-1757934c8fbd',
  'Vessel Traffic and CO2 Emissions': '86fe6abe-0010-47a9-b2d3-58f3685f5e8c',
}

export const toMapboxCoordinates = (coords: number[]) => {
  const [longStart, latStart, longEnd, latEnd] = coords
  return [
    [longStart, latStart],
    [longEnd, latStart],
    [longEnd, latEnd],
    [longStart, latEnd],
    [longStart, latStart]
  ]
}
