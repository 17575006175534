import React, { useEffect, useRef, useState } from "react"

import ChevronUpIcon from "../icons/ChevronUpIcon"
import { AnimatePresence, motion } from "framer-motion"
import styled from "styled-components"
import { mediaMax } from "../OdpGlobalComponents/mixMediaQuery"
import { token } from "../OdpGlobalComponents/tokens"
import { colors } from "../../styles/colors.style"

const DropDownContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  justify-content: flex-end;
  align-items: center;

  & > p {
    margin-right: 20px;
  }

  ${mediaMax.WIDTH_3} {
    & > p {
      font-size: 13px;
      margin-right: 10px;
    }
  }

  ${mediaMax.WIDTH_2} {
    & > p {
      display: none;
    }
  }
`

const DropDownButton = styled.button`
  width: 100%;
  max-width: 170px;
  background-color: transparent;
  border: none;
  font-weight: bold;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
  transition: 0.3s ease;

  ${mediaMax.WIDTH_3} {
    font-size: 13px;
  }
`

const DropDownMenu = styled(motion.ul)`
  background-color: ${colors.white};
  border: 1px solid ${colors.mainVioletO10};
  border-radius: ${token.border.radius.RADIUS_2};
  padding: 15px;
  width: 215px;
  list-style-type: none !important;
  position: absolute;
  right: 0;
  top: 60px;
  margin: 0;
  overflow: hidden;
  z-index: 10000;

  &:hover,
  &:focus {
    box-shadow: 0 0 7px 0 ${colors.mainVioletO20};
  }

  ${mediaMax.WIDTH_2} {
    top: 40px;
  }
`

interface ItemProps {
  selected: boolean
}

const DropDownItemButton = styled.button<ItemProps>`
  background-color: transparent;
  border: none;
  padding: 18px 5px 18px 18px;
  display: flex;
  transition: .3s ease;
  width: 100%;
  color: ${props =>
    props.selected ? `${colors.mainViolet}` : "inherit"};
  font-weight: ${props => (props.selected ? "bold" : "inherit")};
  cursor: pointer;

  &:hover {
    color: ${colors.mediumPurple};
  }
  &:focus{
    background-color: ${colors.mainVioletO05};
  }
;
`

const menuVariants = {
  hidden: {
    maxHeight: 0,
    opacity: 0,
  },
  visible: {
    maxHeight: 700,
    opacity: 1,
    transition: {
      duration: 0.4,
    },
  },
}

interface Props {
  handleSelection: (value: string) => void
  categories: Array<string>
  selectedCategory: string
}

const DropDown = ({ handleSelection, categories, selectedCategory }: Props) => {
  const [isOpen, setOpen] = useState(false)
  const ref = useRef<HTMLUListElement | null>(null)

  useEffect(() => {
    document.addEventListener("click", handleClickOutside)

    return () => {
      document.removeEventListener("click", handleClickOutside)
    }
  })

  const handleClickOutside = (e: MouseEvent) => {
    if (isOpen) {
      if (ref.current && !ref.current.contains(e.target as Node)) {
        setOpen(false)
      }
    }
  }

  const generateAppItems = categories.map((item) => {
    return (
      <DropDownItemButton
        selected={item === selectedCategory}
        onClick={() => handleSelection(item === "All" ? "" : item)}
        key={item}>
        {item}
      </DropDownItemButton>
    )
  })

  return (
    <DropDownContainer>
      <p>Filter by</p>
      <DropDownButton onClick={() => setOpen(!isOpen)}>
        {selectedCategory} <ChevronUpIcon isOpen={isOpen} />
      </DropDownButton>
      <AnimatePresence>
        {isOpen && (
          <DropDownMenu
            initial={"hidden"}
            exit={"hidden"}
            animate={"visible"}
            variants={menuVariants}
            ref={ref}>
            {generateAppItems}
          </DropDownMenu>
        )}
      </AnimatePresence>
    </DropDownContainer>
  )
}

export default DropDown
