import { IDataProductMeta } from "odp-sdk/dist/types"
import React from "react"
import { S } from "./Tabs.shared.style"

type Props = {
  dataProduct: IDataProductMeta
}

const CitationTab = ({ dataProduct }: Props) => {
  return (
    <S.Tab>
      <h4>Citation</h4>
      <p>{dataProduct.citation}</p>
    </S.Tab>
  )
}

export default CitationTab
