import log from "loglevel"
import { ODPClient } from "odp-sdk"
import { useMemo } from "react"

let odp: ODPClient | null = null

const createOdpClient = () => {
  return new ODPClient(
    {
      appId: "Ocean Data Explorer",
      baseUrl: "https://api.cognitedata.com",
      logLevel: log.getLevel(),
    },
    {
      clientId: "5c615a6e-62c2-4bca-905e-7bc148a4ade2", // Ocean Data Explorer
      redirectUri: `${location.protocol}//${location.host}/`,
      postLogoutRedirectUri: "https://www.oceandata.earth",
    }
  )
}

export function getODPClient() {
  odp = odp ?? createOdpClient()
  return odp
}

// Log out with redirect-url
export async function logOut() {
  try {
    getODPClient().unauthorizeUser()
  } catch (err) {
    log.error(err)
  }
}

export const useOdpClient = () => {
  return useMemo(getODPClient, [])
}
