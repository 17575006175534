import styled from "styled-components"

const Tab = styled.div`
  padding-right: 20px;

  h4 {
    margin: 0;
    margin-bottom: 8px;
    font-size: 20px;
    line-height: 24px;
  }

  p {
    margin: 5px 0;
    font-size: 14px;
    line-height: 28px;
  }

  div {
    display: flex;
    align-items: flex-end;
    line-height: 24px;
    margin: 5px 0;
    strong {
      min-width: 100px;
      max-width: 100px;
    }
  }

  a {
    color: inherit
  }
`

const ClickableSpan = styled.span`
  cursor: pointer;
  text-decoration: underline;
`

const SeparatorSpan = styled.span`
  margin-right: 5px;
`

export const S = {
  Tab,
  ClickableSpan,
  SeparatorSpan
}
