import styled from "styled-components"
import { mediaMin } from "../../../../components/OdpGlobalComponents/mixMediaQuery"
import { colors } from "../../../../styles/colors.style"
import { StyledPage } from "../../../../styles/shared.style"

const Page = styled(StyledPage)``

const Container = styled.div`
  max-width: 1200px;
`

const TopContainter = styled.div`
  display: flex;
  flex-direction: column;

  ${mediaMin.WIDTH_2} {
    flex-direction: row;
    justify-content: space-between;
  }
`

const TagsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -5px;
    
  span {
    margin: 5px;
  }
`

const DetailsNavigation = styled.div`
  margin-top: 40px;
  display: flex;
  margin-left: -10px;
`

const SectionButton = styled.span<{ selected: boolean }>`
  font-size: 14px;
  letter-spacing: 0.1em;
  line-height: 28px;
  white-space: nowrap;
  margin: 0 10px;
  text-transform: uppercase;
  padding: 0 10px;
  border-bottom: 2px solid ${colors.mainVioletO10};
  cursor: pointer;

  ${props =>
    props.selected
      ? `border-color: ${colors.mainViolet}`
      : `&:hover {
    border-color: ${colors.mainVioletO30};
  }`}
`

const Main = styled.div`
  margin-top: 30px;
  font-size: 14px;
  line-height: 28px;
  display: grid;

  ${mediaMin.WIDTH_4} {
    display: flex;
    justify-content: space-between;
  }

  > div {
    margin-bottom: 20px;
  }
`

const MapSection = styled.div`
  ${mediaMin.WIDTH_4} {
    margin-left: 40px;
  }

  span {
    display: block;
    margin-left: 10px;
    margin-top: 12px;
  }
`

const LoaderWrapper = styled.div`
  margin-top: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
`

const ButtonContainter = styled.div`
  display: flex;
  margin-top: 18px;

  button {
    margin-right: 10px;
  }
  
  ${mediaMin.WIDTH_2} {
    flex-direction: column;
    margin-top: 0;

    button {
      margin-top: 5px;
      margin-right: 0px;
      margin-bottom: 5px;
      margin-left: 40px;
    }
  }

`

export const S = {
  Page,
  TagsWrapper,
  DetailsNavigation,
  SectionButton,
  Main,
  MapSection,
  LoaderWrapper,
  TopContainter,
  Container,
  ButtonContainter
}
