import mapboxgl from "mapbox-gl"
import React, { useEffect } from "react"
import { colors } from "../../../../../styles/colors.style"
import { toMapboxCoordinates } from "../../../catalog.helpers"
import { S } from "./MiniMap.style"
import 'mapbox-gl/dist/mapbox-gl.css';

type Props = {
  boundingBox: number[]
}

const MiniMap = ({ boundingBox }: Props) => {
  useEffect(() => {
    const map = new mapboxgl.Map({
      container: "minimap",
      zoom: 0,
      style: "mapbox://styles/oceandatafoundation/cl3k5692q003515quoztlubas",
      bounds: new mapboxgl.LngLatBounds(
        new mapboxgl.LngLat(-180, 90),
        new mapboxgl.LngLat(180, -90)
      ),
      interactive: false,
      accessToken:
        "pk.eyJ1Ijoib2NlYW5kYXRhZm91bmRhdGlvbiIsImEiOiJjazk5bGxpNWkwYWU1M2Vya3hkcHh4czdrIn0.yf7kIiPfDNE7KP9_9wTN6A",
    })

    map.on("load", () => {
      map.addSource("bbox", {
        type: "geojson",
        data: {
          type: "Feature",
          properties: {},
          geometry: {
            type: "Polygon",
            coordinates: [toMapboxCoordinates(boundingBox)],
          },
        },
      })

      map.addLayer({
        id: "outline",
        type: "line",
        source: "bbox",
        layout: {},
        paint: {
          "line-color": colors.seaGreen,
          "line-width": 2,
        },
      })
    })
  }, [boundingBox])

  return <S.MapWrapper id="minimap" />
}

export default MiniMap
