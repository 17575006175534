import { createStore, combineReducers, applyMiddleware, compose } from "redux"
import explorer from "./reducers/explorer"
import catalog from "./reducers/catalog"
import infoPanel from "./reducers/infoPanel"
import user from "./reducers/user"
import thunkMiddleware from "redux-thunk"
import { createLogger } from "redux-logger"

// only for redux logging
const loggerMiddleware = createLogger({ collapsed: true })

const middlewareEnhancer = process.env.NODE_ENV !== "production"
  ? applyMiddleware(thunkMiddleware, loggerMiddleware)
  : applyMiddleware(thunkMiddleware)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
  combineReducers({ user, explorer, infoPanel, catalog }),
  composeEnhancers(middlewareEnhancer)
)
