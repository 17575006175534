import "@fontsource/roboto/700.css"
import styled from "styled-components"
import { token } from "../../../components/OdpGlobalComponents/tokens"

const ConnectorSdksContentWrapper = styled.div`
  padding: 8rem 4rem 8rem 4rem;
`
const ConnectorSdksWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 3rem;
`
const ConnectorSdkWrapper = styled.div`
  font-family: ${token.typography.family.FAMILY_1};
  font-size: 14px;
  line-height: 24px;
  margin-right: 4rem;
  padding-bottom: 3rem;
  width: 270px;
`

const ConnectorSdkHeadline = styled.div`
  font-weight: 700;
`
const ConnectorSdkDescription = styled.div`
  font-weight: 400;
  margin-bottom: 2rem;
`

export const S = {
  ConnectorSdksContentWrapper,
  ConnectorSdksWrapper,
  ConnectorSdkWrapper,
  ConnectorSdkHeadline,
  ConnectorSdkDescription,
}
