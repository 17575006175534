import styled from "styled-components"
import { mediaMin } from "../../../../components/OdpGlobalComponents/mixMediaQuery"

const ResultsWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;

  ${mediaMin.WIDTH_4} {
    flex-direction: row;
  }
`

export const S = {
  ResultsWrapper,
}
