import styled from "styled-components"
import { mediaMin } from "../../../../../components/OdpGlobalComponents/mixMediaQuery"
import { token } from "../../../../../components/OdpGlobalComponents/tokens"
import { colors } from "../../../../../styles/colors.style"
import { S } from "../../../Catalog.shared.style"

const Results = styled.div`
  padding: ${token.space.SPACING_4} 0;

  ${mediaMin.WIDTH_4} {
    padding: ${token.space.SPACING_4} ${token.space.SPACING_7};
  }
  flex: 0.7;
`

const AddButton = styled(S.AddButtonGray)`
  margin-top: ${token.space.SPACING_4};
  ${mediaMin.WIDTH_4} {
    margin-top: 0;
  }
`

const AddButtonContainer = styled.div`
  display: flex;

  ${mediaMin.WIDTH_4} {
    justify-content: flex-end;
    min-width: 170px;
  }
`

const ResultEntry = styled.div`
  padding: ${token.space.SPACING_4} ${token.space.SPACING_7};
  padding-left: 0;
  border-radius: ${token.border.radius.RADIUS_3};
  display: flex;
  flex-direction: column;
  
  ${mediaMin.WIDTH_4} {
    align-items: center;
    flex-direction: row;
    padding-left: ${token.space.SPACING_7};

    &:hover {
      background-color: ${colors.mainVioletO03};
      transition: background-color 100ms;
    }
  }

  h3 {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: ${token.space.SPACING_1};
    a {
      color: inherit;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  p {
    font-size: 14px;
    line-height: 24px;
    margin: 0;
    &:first-of-type {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
`

const ProviderTag = styled.span`
  color: ${colors.surfieGreen};
`

export const Styled = {
  Results,
  ResultEntry,
  AddButtonContainer,
  AddButton,
  ProviderTag,
}
